import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "../../ui/alert-dialog"
import { Button } from "../../ui/button";
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import { toast, Zoom } from "react-toastify";
import { LogOut } from "lucide-react";
import { usePermissions } from './PermissionsProvider';

function Logout() {

  const navigate = useNavigate();
  const { setAuthSecur, utilisateur  } = usePermissions();
  
  const handleLogout = async () => {
    try {
      // Enregistrer l'action de déconnexion dans l'historique
      await axios.post(`${process.env.REACT_APP_API_URL}/info/historique`, {
        user: utilisateur,
        requestType: "Déconnexion",
        requestTime: new Date().toISOString(),
      });
      //D&econnexion
      await axios.get(`${process.env.REACT_APP_API_URL}/logout`, { withCredentials: true });
      setAuthSecur(false); // Mettre à jour l'état d'authentification
      navigate("/login");
      toast.info("Tu as été déconnecté(e) avec succès", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        theme: "colored",
        transition: Zoom,
      }); 
    } catch (error) {
      console.error('Erreur lors de la déconnexion:', error);
      toast.error("Une erreur s'est produite lors de la déconnexion", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        theme: "colored",
        transition: Zoom,
      });
    }
  };

  return (
      <AlertDialog>
        <AlertDialogTrigger asChild>         
          <Button variant="link"><LogOut size={20} />Déconnexion</Button>
        </AlertDialogTrigger>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Confirmation</AlertDialogTitle>
            <AlertDialogDescription>
              Voulez vous vraiment déconnecter de votre compte ?
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>Annuler</AlertDialogCancel>
            <AlertDialogAction  onClick={handleLogout}>Continuer</AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
  );
}

export default Logout;


 
