import React from 'react'

function Sauvegardes() {
  return (
    <div className="p-6 dark:bg-base-200 bg-base-200 rounded-lg shadow-md">
      <div className="mx-auto">
        <h2 className="text-center text-2xl font-bold mb-4">Récupération de sauvegardes</h2>
        <div className="divider"></div>
        <div className="relative w-full mx-auto">
          <img
            src="/construction.png"
            alt="under construction"
            className="block w-full h-auto object-contain"
          />
          <div className="absolute animate-pulse inset-0 flex items-center justify-center text-white text-5xl font-bold">
            En construction
          </div>
        </div>
      </div>
    </div>
  )
}

export default Sauvegardes