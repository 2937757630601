
import { Button } from "../../ui/button"
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "../../ui/card"
import { InputLogin } from "../../ui/inputLogin"
import { Label } from "../../ui/label"
import { CircleUserRound, Eye, EyeOff } from "lucide-react"
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { toast, Zoom } from 'react-toastify';
import '../../../node_modules/react-toastify/dist/ReactToastify.min.css';
import Themes from "../Component/Themes"
import { usePermissions } from '../Component/PermissionsProvider';

function LoginSignup() {
    const [utilisateur,setUtilisateur] = useState('');
    const [pass,setPass] = useState('');
    const [view, setView] = useState(false);
    const navigate = useNavigate();
    const { setAuthSecur, fetchPermissions } = usePermissions();
    
        // Regarde si il y a déjà un token 
        useEffect(() => {
            const fetchData = async () => {
                try {
                    const response = await axios.get(`${process.env.REACT_APP_API_URL}/auth`, { withCredentials: true });
                    if (response.data.isAuthenticated) {
                        setTimeout(() => {
                            navigate("../dashboard/performance");
                        }, 500); // Ajout 1 de delai seconde avant la redirection
                    }
                } catch (error) {
                    console.error("Erreur lors de la vérification de l'authentification:", error);
                }
            };
    
            fetchData();
        }, [navigate]);


        // Vue ou non du password
        function handleView() {
            setView(!view);
        };
    
        //POST AUTH //AXIOS ENVOIE DONNEES LOGIN
        const handleSubmit = (event) => {  
            event.preventDefault(); // empêche le navigateur de soumettre le formulaire
    

            //Check utilisateur and password true  
            axios.post(`${process.env.REACT_APP_API_URL}/user`, {utilisateur,pass}, { withCredentials: true })
            .then((response) => {
                    const token = response.data.token;
                    if (token) {
                        setAuthSecur(true) // maj etat d'authentification
                        fetchPermissions(); // Recharge les permissions
                        setUtilisateur("");
                        setPass("");                       
                        setTimeout(() => {
                            navigate("../dashboard/performance");
                        }, 1000); // Ajout 1 de delai seconde avant la redirection
                        toast.success("Bonjour " + utilisateur + ", l'authentification est réussi", {
                            position: "top-center",
                            autoClose: 10000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: false,
                            progress: undefined,
                            theme: "colored",
                            transition: Zoom,
                        });
                        // Enregistrer l'action de connexion
                        axios.post(`${process.env.REACT_APP_API_URL}/info/historique`, {
                            user: utilisateur,
                            requestType: "Connexion au site",
                            requestTime: new Date().toISOString(),
                        });
                        window.location.reload(); //rechargement de la page pour prendre en compte la connexion reussi    
                    } else {
                        toast.warn(response.data.message, {
                            position: "top-center",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: false,
                            progress: undefined,
                            theme: "colored",
                            transition: Zoom,
                        });
                      }
                    })
                    .catch(error => {
                      console.error(error);
                      toast.error("Erreur lors de la connexion", {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                        theme: "colored",
                        transition: Zoom,
                      });
                    });

                   
        };

  return (

        
    <div className="flex items-center justify-center min-h-screen">
        <video loop muted autoPlay  className="absolute top-0 left-0 w-full h-full object-cover">
            <source src="/background01.mp4" type="video/mp4"/>
          </video>
    <form className="absolute"  action="" onSubmit={handleSubmit}>
        <Card className="mx-auto max-w-sm bg-base-100 bg-opacity-80">
        <CardHeader>
                <div className="flex justify-between items-center">
                    <CardTitle className="text-2xl">Administration</CardTitle>
                    <Themes />
                </div>
            <CardDescription>
                Entrez votre Nom d'utilisateur ci-dessous pour vous connecter à votre compte
            </CardDescription>
        </CardHeader>
        <CardContent>
            <div className="grid gap-4">
            <div className="grid gap-2">
                <Label htmlFor="user">Utilisateur</Label>
                <div className='inline'>
                <InputLogin
                    type="text" 
                    placeholder='Utilisateur'
                    value={utilisateur}
                    onChange={(e)=>setUtilisateur(e.target.value)}
                    suffix={<CircleUserRound />}  
                    required
                /> 
                </div>
            </div>
            <div className="grid gap-2">
                <div className="flex items-center">
                <Label htmlFor="password">Mot de passe</Label>
                <Link to="/pass" className="ml-auto inline-block text-sm underline">
                    Mot de passe oublié ?
                </Link>
                </div>
                {view ? <InputLogin 
                            type="text" 
                            placeholder='Mot de passe'
                            value={pass}
                            onChange={(e)=>setPass(e.target.value)}
                            suffix={<div className="cursor-pointer" onClick={handleView}>
                                        <Eye />
                                    </div>}   
                            required
                        /> 
                        : 
                        <InputLogin 
                            type="password" 
                            placeholder='Mot de passe'
                            value={pass}
                            onChange={(e)=>setPass(e.target.value)}
                            suffix={<div className="cursor-pointer" onClick={handleView}>
                                        <EyeOff />
                                    </div>}    
                            required
                        /> 
                }
            </div>
            <Button type="submit" className="w-full text-white bg-gradient-to-br from-purple-600 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2">
                Entrer
            </Button>
            
            <Button variant="outline" className="w-full ">
                S'identifier avec Google
            </Button>
            </div>
            <div className="mt-4 text-center text-sm">
                Pas de compte ?{" "}
            <Link to="/compte" className="underline">
                S'inscrire
            </Link>
            </div>
        </CardContent>
        </Card>
   </form> 
   </div>
  );
};

export default LoginSignup
