import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button } from "../../ui/button";
import { ScrollArea } from "../../ui/scroll-area";

function Historique() {

  const [requestHistory, setRequestHistory] = useState([]);

  useEffect(() => {
    const fetchRequestHistory = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/historique`);
        setRequestHistory(response.data);
      } catch (error) {
        console.error('Error fetching request history:', error);
      }
    };

    fetchRequestHistory();
  }, []);


  //Téléchargement du fichier
  const handleDownload = () => {
    window.location.href = `${process.env.REACT_APP_API_URL}/download-historique`;
  };

  return (
    <div className="p-6 dark:bg-base-200 bg-base-200 rounded-lg shadow-md">
    <div className="mx-auto">
      <h2 className="text-center text-2xl font-bold mb-4">Historique des Requêtes</h2>
      <div className="divider"></div>
      <ScrollArea className="rounded-md border max-h-[650px] overflow-auto">
        <table className="dark:border-stone-800 dark:bg-stone bg-base-300 table w-full">
          <thead className="dark:bg-stone-900 bg-gray-300">
            <tr>
              <th>Utilisateur</th>
              <th>Détails de la Requête</th>
              <th>Jour et Heure</th>
            </tr>
          </thead>
          <tbody>
            {requestHistory.reverse().map((request, index) => (
              <tr 
                key={index} 
                className="hover:bg-violet-300 dark:hover:bg-indigo-500 dark:focus:bg-indigo-600 focus:bg-blue-400"
                tabIndex={0}
              >
                <td>{request.user}</td>
                <td>{request.requestType}</td>
                <td>{new Date(request.requestTime).toLocaleString()}</td>
              </tr>
            ))}
          </tbody>
        </table> 
      </ScrollArea>
      <div className="mt-6 flex justify-end">
        <Button variant="confirm" onClick={handleDownload}>
          Télécharger l'historique
        </Button>
      </div>
    </div>
  </div>
);
}

export default Historique
