import React, { useState } from "react";
import { toast, Zoom } from "react-toastify";
import { NavLink } from "react-router-dom";
import axios from "axios";
import { Button } from "../../ui/button"
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../../ui/card"
import { Input } from "../../ui/input"
import { Label } from "../../ui/label"
import Themes from "../Component/Themes";

function SignUp() {


//REQUEST ----->

//SET AXIOS POST
const id = ""
const [utilisateur,setUtilisateur] = useState("")
const [email,setEmail] = useState("")
const [pass,setPass] = useState("")
const [passConfirm,setPassConfirm] = useState("")


//Regex email
const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,})$/;
const isValidEmail = emailRegex.test(email);

//Regex Nom utilisateur
const userRegex = /^[a-zA-Z0-9_]{3,15}$/;
const isValidUser = userRegex.test(utilisateur);

//Regex mot de passe
const passRegex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&_-])[A-Za-z\d@$!%*#?&_-]{8,}$/;
const isValidPass = passRegex.test(pass); 


//AXIOS ENVOIE DONNEES
const handleClick = (event)=>{
    event.preventDefault(); // empêche le navigateur de soumettre le formulaire
    if(isValidEmail === true){
        //Check password true
        if (pass === passConfirm && pass !== undefined) {
            if(isValidUser === true){
                if(isValidPass === true){
                    axios.post(`${process.env.REACT_APP_API_URL}/api/post`, {id,utilisateur,pass,email})
                            .then((response)=>{
                        
                    const info = response.data.sign  // retourne Boolean
                    if(info){
                        //historique  
                        axios.post(`${process.env.REACT_APP_API_URL}/info/historique`, {
                            user: "INSCRIPTION",
                            requestType: "L'utilisateur " + utilisateur + " a fait une demande d'insciption",
                            requestTime: new Date().toISOString(),
                        }); 
                        toast.success("Inscription envoyé, un administrateur doit validé votre inscription pour pouvoir vous connectez", {
                            position: "top-center",
                            autoClose: 30000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: false,
                            progress: undefined,
                            theme: "colored",
                            transition: Zoom,
                        });
                        setUtilisateur("")
                        setEmail("")
                        setPass("")
                        setPassConfirm("")       
                    }                
                    else {
                        toast.warn(response.data.message, {
                            position: "top-center",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: false,
                            progress: undefined,
                            theme: "colored",
                            transition: Zoom,
                        });       
                    }
                    });
                } else {
                    toast.warn("Format mot de passe invalide : Minimum huit caractères, au moins une lettre, un chiffre et un caractère spécial", {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                        theme: "colored",
                        transition: Zoom,
                    }); 
                }
            } else {
                toast.warn("Le Format du nom d'utilisateur est invalide ", {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                        theme: "colored",
                        transition: Zoom,
                });    
            }
        }        
        //Check password false
        if (pass !== passConfirm && pass !== undefined) {
            toast.warn("Les mots de passe ne correspondent pas.", {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                theme: "colored",
                transition: Zoom,
            });

        } 
    } else {
        toast.error("Format d'email invalide", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
            theme: "colored",
            transition: Zoom,
        });

    }     
}



// HTML ----->
    return (
        <div className="flex items-center justify-center min-h-screen">
          <video loop muted autoPlay  className="absolute top-0 left-0 w-full h-full object-cover">
            <source src="/background01.mp4" type="video/mp4"/>
          </video>
        <form className='absolute' onSubmit={handleClick}>
            <Card className="bg-base-100 bg-opacity-80 w-full max-w-sm">
                <CardHeader>
                    <div className="flex justify-between items-center">
                        <CardTitle className="text-2xl">Inscription</CardTitle>
                        <Themes />
                    </div>
                    <CardDescription>
                    Merci de renseigner toutes les informations pour valider le formulaire d'inscription
                    </CardDescription>
                </CardHeader>
                <CardContent className="grid gap-4">
                    <div className="grid gap-2">
                    <Label htmlFor="user">Utilisateur</Label>
                    <Input
                            id="user" 
                            type="text" 
                            placeholder='Utilisateur'
                            value={utilisateur} 
                            onChange={(e)=>setUtilisateur(e.target.value)} 
                            required 
                    />
                    </div>
                    <div className="grid gap-2">
                    <Label htmlFor="email">Email</Label>
                    <Input
                            id="email" 
                            type="text" 
                            placeholder='blabla@exemple.com'
                            value={email} 
                            onChange={(e)=>setEmail(e.target.value)} 
                            required   
                    />
                    </div>
                    <div className="grid gap-2">
                    <Label htmlFor="pass">Mot de passe</Label>
                    <Input
                            id="pass" 
                            type="text" 
                            placeholder='Mot de passe'
                            value={pass} 
                            onChange={(e)=>setPass(e.target.value)} 
                            required 
                    />
                    </div>
                    <div className="grid gap-2">
                    <Label htmlFor="passConfirm">Confirmer le mot de pass</Label>
                    <Input
                            id="passConfirm" 
                            type="text" 
                            placeholder='Confirmer le mot de passe'
                            value={passConfirm}
                            onChange={(e)=>setPassConfirm(e.target.value)}  
                            required 
                    />
                    </div>
                </CardContent>
                <CardFooter>
                    <NavLink to="/login" className="w-20 mr-5 flex items-center justify-center text-sm font-medium h-10 rounded-md px-8 border border-input bg-background hover:bg-accent hover:text-accent-foreground">
                        Retour
                    </NavLink>
                    
                    <Button className="w-60 text-white bg-gradient-to-br from-purple-600 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm text-center ">
                        Envoyer
                    </Button>               
                </CardFooter>
            </Card>
        </form>
    </div>    
    )
}


export default SignUp

