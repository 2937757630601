import React from "react";
import { useState, useEffect } from "react";



function Themes(){

    
    // Gère la couleur en local
    const [dark, setDark] = useState(JSON.parse(localStorage.getItem('dark')) || false);

    useEffect(() => {
    // Appliquer le thème initial lors du montage du composant
    document.body.setAttribute('data-theme', dark ? 'forest' : 'winter');

    // Mettre à jour le localStorage et les classes CSS lorsque l'état change
    localStorage.setItem('dark', JSON.stringify(dark));
    }, [dark]);
     
    function handleChange() {
        setDark(!dark);     
    }

    return (
        <div className="flex items-center justify-center p-4">
            <label className="grid cursor-pointer place-items-center">
                <input 
                    onChange={handleChange}
                    type="checkbox"
                    value="synthwave"
                    className="toggle theme-controller bg-base-content col-span-2 col-start-1 row-start-1"
                    checked={dark} // Utilisez l'état `dark` pour définir l'attribut `checked`
                />
                <svg
                    className="stroke-base-100 fill-base-100 col-start-1 row-start-1"
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="14"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round">
                    <circle cx="12" cy="12" r="5" />
                    <path
                    d="M12 1v2M12 21v2M4.2 4.2l1.4 1.4M18.4 18.4l1.4 1.4M1 12h2M21 12h2M4.2 19.8l1.4-1.4M18.4 5.6l1.4-1.4" />
                </svg>
                <svg
                    className="stroke-base-100 fill-base-100 col-start-2 row-start-1"
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="14"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round">
                    <path d="M21 12.79A9 9 0 1 1 11.21 3 7 7 0 0 0 21 12.79z"></path>
                </svg>
            </label>               
        </div>
    );
}

export default Themes