import React from 'react';
import Logout from '../Component/Logout';
import { NavLink, Outlet } from 'react-router-dom';
import { useEffect, useState } from 'react';
import axios from 'axios';
import Themes from '../Component/Themes';
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
} from "../../ui/avatar"
import { AiFillGithub } from "react-icons/ai";
import {
  ChartNoAxesCombined,
  FileClock,
  Server,
  ServerCog,
  DatabaseBackup,
  MessageCircleQuestion,
  MessagesSquare,
  ContactRound,
  CircleUserRound,
  PanelRightClose
} from 'lucide-react';
import { Badge } from '../../ui/badge';

function Dashboard() {

  const [newData, setNewData] = useState(null);
  const [unreadMessagesCount, setUnreadMessagesCount] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/auth`, { withCredentials: true });
        if (response.data.isAuthenticated) {
          setNewData(response.data.userData);
          // Mettre à jour le contexte avec les nouvelles données
        } else {
          console.log('User is not authenticated');
        }
      } catch (error) {
        console.error('Auth check failed:', error);
      }
    };

    fetchData();
  }, [setNewData]);

  useEffect(() => {
    const fetchUnreadMessagesCount = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/unread-messages-count`, { withCredentials: true });
        setUnreadMessagesCount(response.data.count);
      } catch (error) {
        console.error('Failed to fetch unread messages count:', error);
      }
    };

    fetchUnreadMessagesCount();

    const interval = setInterval(fetchUnreadMessagesCount, 5000);

    return () => clearInterval(interval);
  }, []);

  const img = newData ? `${process.env.REACT_APP_API_URL}${newData.imagePath}` : ''; // En cas d'erreur l'image est <AvatarFallback>
  const user = newData ? newData.utilisateur : '';
  const grade = newData ? newData.grade : '';

    //Creation des tableaux de la SlideBar
    const navLinks = [
      { to: "performance", icon: <ChartNoAxesCombined size={20} />, text: "Performance" },
      { to: "historique", icon: <FileClock size={20} />, text: "Historique" },
      { to: "serveurs", icon: <Server size={20} />, text: "Serveurs jeux" },
      { to: "installation", icon: <ServerCog size={20} />, text: "Installation jeux" },
      { to: "sauvegardes", icon: <DatabaseBackup size={20} />, text: "Backups jeux" },
    ];

    const navLinks2 = [
      { to: "support", icon: <MessageCircleQuestion size={20} />, text: "Aide et support" },
      { to: "chatbox", icon: <MessagesSquare size={20} />, text: "Chatbox" },
      { to: "membres", icon: <ContactRound size={20} />, text: "Membres" },
      { to: "profil", icon: <CircleUserRound size={20} />, text: "Profil" },
    ];

    const custom = "flex dark:focus:bg-gray-800 focus:bg-slate-300 items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-200 dark:hover:bg-gray-700 group";
    const iconCustom = "text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white";

    function renderNavLink(link) {
      return (
        <NavLink to={link.to} className={custom} key={link.to}>
          <span className={iconCustom}>{link.icon}</span>
          {link.text}
          {link.to === 'chatbox' && unreadMessagesCount > 0 && (
            <Badge 
              className="ml-[30%]"
              variant="notif"
            >
            +{unreadMessagesCount}
            </Badge>
          )}
        </NavLink>
      );
    };

 
  return (
    <div className="drawer lg:drawer-open">
      <input id="my-drawer" type="checkbox" className="drawer-toggle" />
      <div className="drawer-content flex flex-col items-center justify-center">
        <label htmlFor="my-drawer" className="btn btn-primary drawer-button lg:hidden">
          Menu<PanelRightClose size={20} className="ml-1" />
        </label>
        <div className="w-full flex-1 p-4">
          <Outlet />
        </div>
      </div>
      <div className="drawer-side">
        <label htmlFor="my-drawer" aria-label="close sidebar" className="drawer-overlay"></label>
        <div className="w-60 h-screen dark:bg-base-200 bg-slate-100 flex flex-col">          
          <div className="p-1 pl-3 flex items-center mt-14 mx-2 dark:bg-base-100 bg-base-100 rounded-lg shadow-md">
            <Avatar>
              <AvatarImage src={img} alt="User" />
              <AvatarFallback>Oops</AvatarFallback>
            </Avatar>
            <div className='pl-1 flex-col'>
              <p className="dark:text-sky-400 text-indigo-500 text-base font-bold ml-2">
                {user}
              </p>
              <div className="pl-5">
                <Badge  variant={grade}>{grade}</Badge>
              </div>
            </div>
          </div>
          <div className='flex pl-4 mb-5 mt-10'>
            <h1 className="pt-3 text-2xl font-thin-bold ">Dashboard</h1>
            <Themes />
          </div>  
          
          <div className="ml-1 flex flex-col">
            <p className="pl-2 text-sm dark:text-stone-400 text-slate-700">Serveur dédié</p>
            {navLinks.map(renderNavLink)}
            <div className="mx-8 divider"></div>
            <p className="pl-2 text-sm dark:text-stone-400 text-slate-700">Utilitaire</p>
            {navLinks2.map(renderNavLink)} 
          </div>
          <div className="mt-auto p-4">
            <a
              className="flex mb-2 items-center hover:text-sky-700"
              href='https://github.com/Blue-sTw'
              target="_blank"
              rel="noopener noreferrer"
            >
              <AiFillGithub />
              <p className='text-xs'>.Blue-sTw</p>
            </a>
            <Logout />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;