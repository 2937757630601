import './App.css';
import LoginSignup from './Components/LoginSignup/LoginSignup';
import SignUp from './Components/LoginSignup/SignUp';
import PassForgot from './Components/LoginSignup/PassForgot';
import {Routes, Route} from 'react-router-dom';
import Dashboard from './Components/Dashboard/Dashboard';
import PrivateRoute from './Components/Component/PrivateRoute';
import AuthProvider from './Components/Component/AuthProvider';
import UserProfil from './Components/Dashboard/UserProfil';
import Performance from './Components/Dashboard/Performance';
import Historique from './Components/Dashboard/Historique';
import Installation from './Components/Dashboard/Installation';
import Sauvegardes from './Components/Dashboard/Sauvegardes';
import Support from './Components/Dashboard/Support';
import Serveurs from './Components/Dashboard/Serveurs';
import Chatbox from './Components/Dashboard/Chatbox';
import Membres from './Components/Dashboard/Membres';
import { PermissionsProvider } from './Components/Component/PermissionsProvider';
import ResetPassword from './Components/LoginSignup/ResetPassword';
import Serveur from './Components/Dashboard/Serveur';
import Ark from './Components/Component/Games/Ark';
import Eco from './Components/Component/Games/Eco';
import Connan from './Components/Component/Games/Connan';
import Counter from './Components/Component/Games/Counter';
import Palworld from './Components/Component/Games/Palworld';
import Satisfactory from './Components/Component/Games/Satisfactory';
import SpaceEng from './Components/Component/Games/SpaceEng';
import Garrys from './Components/Component/Games/Garrys';
import NotFound from './Components/Component/NotFound';

function App() {
  return (
    <div>
        <PermissionsProvider>
        <AuthProvider>
          <Routes>
            <Route path="/login" element={<LoginSignup />} />
            <Route path="/compte" element={<SignUp />} />
            <Route path="/pass" element={<PassForgot />} />            
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="/dashboard" element={<PrivateRoute><Dashboard /></PrivateRoute>}>
                <Route path="profil" element={<PrivateRoute><UserProfil /></PrivateRoute>} />
                <Route path="performance" element={<PrivateRoute><Performance /></PrivateRoute>} />
                <Route path="historique" element={<PrivateRoute><Historique /></PrivateRoute>} />
                <Route path="serveurs" element={<PrivateRoute><Serveurs /></PrivateRoute>} />    
                <Route path="installation" element={<PrivateRoute><Installation /></PrivateRoute>} />
                <Route path="sauvegardes" element={<PrivateRoute><Sauvegardes /></PrivateRoute>} />
                <Route path="support" element={<PrivateRoute><Support /></PrivateRoute>} />
                <Route path="chatbox" element={<PrivateRoute><Chatbox /></PrivateRoute>} />
                <Route path="membres" element={<PrivateRoute><Membres /></PrivateRoute>} />
                <Route path="serveurs/:id" element={<PrivateRoute><Serveur /></PrivateRoute>}>
                    <Route path="Ark" element={<PrivateRoute><Ark /></PrivateRoute>} />
                    <Route path="Eco" element={<PrivateRoute><Eco /></PrivateRoute>} />
                    <Route path="Connan" element={<PrivateRoute><Connan /></PrivateRoute>} />
                    <Route path="Counter" element={<PrivateRoute><Counter /></PrivateRoute>} />
                    <Route path="Palworld" element={<PrivateRoute><Palworld /></PrivateRoute>} />
                    <Route path="Satisfactory" element={<PrivateRoute><Satisfactory /></PrivateRoute>} />
                    <Route path="SpaceEng" element={<PrivateRoute><SpaceEng /></PrivateRoute>} />
                    <Route path="Garrys" element={<PrivateRoute><Garrys /></PrivateRoute>} />
                </Route>
            </Route>
            <Route path="*" element={<NotFound />} />
          </Routes>
        </AuthProvider>
        </PermissionsProvider>              
    </div>
  );

}


export default App;

