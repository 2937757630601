import React from 'react';

function Support() {
  return (
    <div className="p-6 bg-base-200 dark:bg-base-200 rounded-lg shadow-md">
      <div className="mx-auto max-w-4xl">
        <h2 className="text-center text-2xl font-bold mb-4 text-primary">Aide et support</h2>
        <div className="divider"></div>
        <div className="container mx-auto p-4">
          <div className="mb-6">
            <h1 className="text-xl font-bold mb-2 text-secondary animate-pulse">Présentation du Projet</h1>
            <p>
              Ce projet est une application complète pour la gestion de serveurs de jeux, incluant l'inscription, la connexion, la gestion des utilisateurs, l'installation et la gestion des serveurs de jeux, ainsi que la gestion des mods et des configurations de serveurs. Le backend est développé en Node.js avec Express, et le frontend est développé en React.
            </p>
          </div>

          <div className="mb-6">
            <h1 className="text-xl font-bold mb-2 text-secondary animate-pulse">Fonctionnalités</h1>
            <ul className="list-disc list-inside">
              <li>Inscription et connexion des utilisateurs.</li>
              <li>Gestion des profils utilisateurs.</li>
              <li>Installation et gestion des serveurs de jeux.</li>
              <li>Gestion des mods et des configurations de serveurs.</li>
              <li>Réinitialisation des mots de passe via email.</li>
              <li>Surveillance des ressources système.</li>
              <li>Historique des requêtes.</li>
              <li>Notifications de messages.</li>
            </ul>
          </div>

          <div className="mb-6">
            <h3 className="text-xl font-bold mb-2 text-secondary animate-pulse">Authentification</h3>
            <ul className="list-disc list-inside">
              <li>Inscription d'un nouvel utilisateur.</li>
              <li>Connexion d'un utilisateur.</li>
              <li>Vérifier si l'utilisateur est authentifié.</li>
              <li>Déconnexion de l'utilisateur.</li>
            </ul>
          </div>

          <div className="mb-6">
            <h3 className="text-xl font-bold mb-2 text-secondary animate-pulse">Gestion des Profils</h3>
            <ul className="list-disc list-inside">
              <li>Télécharger une nouvelle image de profil.</li>
            </ul>
          </div>

          <div className="mb-6">
            <h3 className="text-xl font-bold mb-2 text-secondary animate-pulse">Gestion des Utilisateurs</h3>
            <ul className="list-disc list-inside">
              <li>Récupérer la liste des membres.</li>
              <li>Récupérer la liste des inscriptions.</li>
              <li>Accepter une inscription.</li>
              <li>Supprimer une inscription.</li>
              <li>Supprimer un utilisateur.</li>
              <li>Passer un utilisateur en administrateur.</li>
              <li>Passer un utilisateur en membre.</li>
            </ul>
          </div>

          <div className="mb-6">
            <h3 className="text-xl font-bold mb-2 text-secondary animate-pulse">Gestion des Serveurs de Jeux</h3>
            <ul className="list-disc list-inside">
              <li>Récupérer la liste des jeux disponibles.</li>
              <li>Récupérer les détails d'un jeu.</li>
              <li>Installer un nouveau serveur de jeu.</li>
              <li>Vérifier le statut de l'installation.</li>
              <li>Récupérer la liste des serveurs installés.</li>
              <li>Vérifier les serveurs en cours de création.</li>
              <li>Récupérer les détails d'un serveur spécifique.</li>
              <li>Démarrer un serveur.</li>
              <li>Arrêter un serveur.</li>
              <li>Redémarrer un serveur.</li>
              <li>Supprimer un serveur.</li>
              <li>Vérifier le statut d'un serveur.</li>
            </ul>
          </div>

          <div className="mb-6">
            <h3 className="text-xl font-bold mb-2 text-secondary animate-pulse">Gestion des Mods</h3>
            <ul className="list-disc list-inside">
              <li>Rechercher des mods.</li>
              <li>Récupérer les détails d'un mod.</li>
              <li>Installer un mod.</li>
              <li>Récupérer la liste des mods installés sur un serveur.</li>
              <li>Supprimer un mod.</li>
            </ul>
          </div>

          <div className="mb-6">
            <h3 className="text-xl font-bold mb-2 text-secondary animate-pulse">Gestion des Configurations</h3>
            <ul className="list-disc list-inside">
              <li>Récupérer la configuration d'un serveur.</li>
              <li>Ajouter un nouveau paramètre à la configuration d'un serveur.</li>
              <li>Supprimer un paramètre de la configuration d'un serveur.</li>
              <li>Mettre à jour les paramètres de la configuration d'un serveur.</li>
            </ul>
          </div>

          <div className="mb-6">
            <h3 className="text-xl font-bold mb-2 text-secondary animate-pulse">Gestion des Logs</h3>
            <ul className="list-disc list-inside">
              <li>Lister les fichiers de log disponibles pour un serveur.</li>
              <li>Lire un fichier de log spécifique.</li>
            </ul>
          </div>

          <div className="mb-6">
            <h3 className="text-xl font-bold mb-2 text-secondary animate-pulse">Gestion des Messages</h3>
            <ul className="list-disc list-inside">
              <li>Récupérer les messages.</li>
              <li>Envoyer un message.</li>
              <li>Récupérer le nombre de messages non lus.</li>
              <li>Réinitialiser le nombre de messages non lus.</li>
            </ul>
          </div>

          <div className="mb-6">
            <h3 className="text-xl font-bold mb-2 text-secondary animate-pulse">Gestion des Cartes</h3>
            <ul className="list-disc list-inside">
              <li>Récupérer les cartes disponibles pour un serveur.</li>
              <li>Ajouter une nouvelle carte à un serveur.</li>
            </ul>
          </div>

          <div className="mb-6">
            <h3 className="text-xl font-bold mb-2 text-secondary animate-pulse">Gestion des Fichiers BAT</h3>
            <ul className="list-disc list-inside">
              <li>Créer le fichier start_server.bat pour un serveur.</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Support;