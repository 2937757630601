import React, { useState, useEffect } from "react";
import axios from "axios";
import Loading from "../Component/Loading";
import { Line, Pie } from 'react-chartjs-2';
import { Chart as ChartJS,
            CategoryScale,
            LinearScale,
            PointElement,
            LineElement,
            Title,
            Tooltip,
            Legend,
            Filler,
            ArcElement
        } from 'chart.js';

ChartJS.register(ArcElement, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Filler);

function Performance() {

  const [systemInfo, setSystemInfo] = useState(null);
  const [memoryData, setMemoryData] = useState([]);
  const [date, setDate] = useState([]);
  const [hour, setHour] = useState([]);
  const [procData, setProcData] = useState([]);
  const [diskSpace, setDiskSpace] = useState({ used: 0, free: 0 });

  useEffect(() => {
    // Fonction pour capitaliser la première lettre d'une chaîne
    const capitalizeFirstLetter = (str) => {
      return str.charAt(0).toUpperCase() + str.slice(1);
    };

    // Fonction pour formater la date avec le mois et le jour en majuscules
    const formatDate = (date, options) => {
      const formattedDate = date.toLocaleString([], options);
      const [weekday, day, month] = formattedDate.split(' ');
      return `${capitalizeFirstLetter(weekday)} ${day} ${capitalizeFirstLetter(month)}`;
    };

    const fetchSystemInfo = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/system-info`);
        setSystemInfo(response.data);

        // Ajout des données Disque Dur
        setDiskSpace(response.data.diskSpace);

        // Date et heure format
        const dateOptions = {
          weekday: 'long',
          day: 'numeric',
          month: 'long',
        };
        const hourOptions = {
          hour: '2-digit',
          minute: '2-digit',
        };

        // Ajouter les données de mémoire et proc au tableau
        setMemoryData(prevData => [...prevData, response.data.memoryUsage]);
        setDate(prevDate => [...prevDate, formatDate(new Date(), dateOptions)]);
        setHour(prevHour => [...prevHour, new Date().toLocaleString([], hourOptions)]);
        setProcData(prevData => [...prevData, response.data.cpuUsage]);

        // Limiter le nombre de points à 10 pour éviter de surcharger le graphique
        if (memoryData.length > 10) {
          setMemoryData(prevData => prevData.slice(1));
        }
        if (procData.length > 10) {
          setProcData(prevData => prevData.slice(1));
        }
        if (date.length > 10 || hour.length > 10) {
          setDate(prevDate => prevDate.slice(1));
          setHour(prevHour => prevHour.slice(1));
        }
      } catch (error) {
        console.error('Erreur lors de la récupération des informations du système:', error);
      }
    };

    fetchSystemInfo();

    // Mettre à jour les informations du système toutes les 5 secondes
    const intervalId = setInterval(fetchSystemInfo, 5000);

    return () => clearInterval(intervalId); // Nettoyer l'intervalle
  }, [memoryData.length, procData.length, date.length, hour.length]);

  // Chart pour la Mémoire
  const dataMemory = {
    labels: hour,
    datasets: [
      {
        label: 'Utilisation de la mémoire (%)',
        data: memoryData,
        fill: true,
        backgroundColor: 'rgba(0, 243, 73, 0.2)',
        borderColor: 'rgba(0, 175, 73, 1)',
        tension: 0.4, // Pour rendre la ligne plus lisse
      },
    ],
  };

  // Chart pour le Processeur
  const dataProc = {
    labels: hour,
    datasets: [
      {
        label: 'Utilisation du Processeur (%)',
        data: procData,
        fill: true,
        backgroundColor: 'rgba(0, 191, 255, 0.2)',
        borderColor: 'rgba(0, 80, 255, 1)',
        tension: 0.4, // Pour rendre la ligne plus lisse
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: false,
        text: '',
      },
    },
    scales: {
      x: {
        beginAtZero: true,
        ticks: {
          display: false, // Désactiver les dates de l'axe des x
        },
      },
      y: {
        beginAtZero: true,
        min: 0,
        max: 100,
        ticks: {
          stepSize: 20,
        },
      },
    },
  };

  // Chart Disque Dur
  const dataDisk = {
    labels: ['Utilisé', 'Libre'],
    datasets: [
      {
        data: [diskSpace.used, diskSpace.free],
        backgroundColor: ['#993333', '#99cc99'],
        hoverBackgroundColor: ['#990000', '#006600'],
        borderColor: ['#A62609', '#24D26D'],
        hoverBorderColor: ['#FE94B4', '#E0FF20'],
      },
    ],
  };

  return (
    <div className="flex-col p-6 dark:bg-base-200 bg-base-200 rounded-lg shadow-md">
      {systemInfo ? (
        <div className="mx-auto">
          <h2 className="text-center text-2xl font-bold mb-4">Informations du système</h2>
          <div className="divider"></div>
          <div className="text-center dark:bg-base-100 bg-base-100 p-6 m-2 rounded-lg shadow-md">
            <h1 className="text-3xl font-bold mb-4">{date[0]}</h1>
            <h1 className="text-3xl font-bold mb-4">Il est : {hour[0]}</h1>
          </div>
          <div className="flex flex-col md:flex-row justify-evenly">
            <div className="dark:bg-base-100 bg-base-100 p-6 m-2 rounded-lg shadow-md mb-6">
              <h3 className="pb-2 text-2xl"><strong>Système : </strong></h3>
              <p>Modèle : {systemInfo.cpus[0].model}</p>
              <p>Architecture : {systemInfo.arch}</p>
              <p>Nombre de CPU : {systemInfo.cpus.length}</p>
              <div className="divider"></div>
              <h4 className="pb-2 text-lg"><strong>Mémoire vive:</strong></h4>
              <p>Mémoire totale : {(systemInfo.totalMemory / (1024 * 1024 * 1024)).toFixed(2)} GB</p>
              <p>Mémoire libre : {(systemInfo.freeMemory / (1024 * 1024 * 1024)).toFixed(2)} GB</p>
              <div className="divider"></div>
              <div>
                <h4 className="pb-2 text-lg"><strong>Espace de stockage:</strong></h4>
                <p>Total : {diskSpace.total} Go</p>
                <p>Libre : {diskSpace.free} Go</p>
                <p>Utilisé : {diskSpace.used} Go</p>
              </div>
              <div className="divider"></div>
            </div>
            <div className="flex flex-col">
              <div className="max-w-lg dark:bg-base-100 bg-base-100 p-6 m-2 rounded-lg shadow-md">
                <p><strong>Utilisation de la mémoire:</strong> {systemInfo.memoryUsage.toFixed(2)}%</p>
                <Line data={dataMemory} options={options} />
              </div>
              <div className="max-w-lg dark:bg-base-100 bg-base-100 p-6 m-2 rounded-lg shadow-md">
                <p><strong>Utilisation du processeur:</strong> {systemInfo.cpuUsage.toFixed(2)}%</p>
                <Line data={dataProc} options={options} />
              </div>
            </div>
            <div className="flex flex-col items-center justify-center dark:bg-base-100 bg-base-100 p-6 m-2 rounded-lg shadow-md">
              <p className="mb-5 text-xl"><strong>Stockage</strong></p>
              <Pie data={dataDisk} />
            </div>
          </div>
        </div>
      ) : (
        <Loading className="p-6 bg-blend-screen rounded-lg shadow-md" />
      )}
    </div>
  );
}

export default Performance;