import React, { useState, useEffect } from "react";
import axios from "axios";
import { Button } from "../../ui/button";
import { toast, Zoom } from "react-toastify";
import { Upload } from 'lucide-react'; 


function UserProfil() {

 const [newData, setNewData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/auth`, { withCredentials: true });
        if (response.data.isAuthenticated) {
          setNewData(response.data.userData);
          setImageUrl(`${process.env.REACT_APP_API_URL}${response.data.userData.imagePath}`);
          // Mettre à jour le contexte avec les nouvelles données
        } else {
          console.log('User is not authenticated');
        }
      } catch (error) {
        console.error('Auth check failed:', error);
      }
    };

    fetchData();
  }, [setNewData]);

  const [imageUrl, setImageUrl] = useState(newData ? `${process.env.REACT_APP_API_URL}${newData.imagePath}` : '');
  const [selectedFile, setSelectedFile] = useState(null);
  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleFileUpload = async () => {
    const formData = new FormData();
    formData.append("profileImage", selectedFile);
    formData.append("utilisateur", newData.utilisateur);
    
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/upload-profile-image`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      
      await axios.post(`${process.env.REACT_APP_API_URL}/info/historique`, {
        user: newData.utilisateur,
        requestType: "Changement de photo de profil",
        requestTime: new Date().toISOString(),
      });

      if (response.status === 200) {
        toast.success("Photo de profil mise a jour", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
          theme: "colored",
          transition: Zoom,
      });
        // Mettre à jour l'URL de l'image de profil
        setImageUrl(`${process.env.REACT_APP_API_URL}/Pictures_Users/${newData.utilisateur}.png?t=${new Date().getTime()}`);
      }
    } catch (error) {
      console.error("Erreur lors du téléchargement de l'image:", error);
      toast.error("Erreur lors du téléchargement de l'image.", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        theme: "colored",
        transition: Zoom,
    });
    
    }
  };

    return (
      <div className="p-6 dark:bg-base-200 bg-base-200 rounded-lg shadow-md">
        {newData && (
          <div className="mx-auto">
            <h2 className="text-center text-2xl font-bold mb-4">Accès Profil</h2>
            <div className="divider"></div>
            
              
        

<div className="hero bg-base-100 rounded-e-full min-h-screen">
  <div className=" hero-content flex-col lg:flex-row">
    <img
      alt=""
      src={imageUrl}
      className="max-w-sm rounded-lg shadow-2xl" />
    <div>
      <h1 className="text-5xl font-bold">Utilisateur : {newData.utilisateur}</h1>
      <p className="pl-10 text-stone-500"><strong>Email:</strong> {newData.email}</p>
      <p className="py-6">
      L'histoire de One Piece se déroule dans un monde fictif dominé par les océans, 
      où certains pirates aspirent à une ère de liberté et d'aventure 
      connue comme « l'âge d'or de la piraterie ».
      </p>
      <h2 className="text-xl font-semibold mb-4">Changer la photo de profil</h2>
      <div className="flex items-center w-full">
                  <label htmlFor="file_input" className="flex mb-2 items-center justify-center px-3 py-1.5  text-blue-100 bg-opacity-50 bg-indigo-950 border border-y-purple-600 border-l-purple-600 border-r-transparent rounded-l-lg shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 cursor-pointer">  
                    <Upload />
                  </label>
                  <input
                    onChange={handleFileChange}
                    className="hidden"
                    aria-describedby="file_input_help"
                    id="file_input"
                    type="file"
                  />
                  <Button 
                    onClick={handleFileUpload} disabled={!selectedFile} 
                    className="flex items-center justify-center py-1.5 rounded-r-lg rounded-l-none"
                    variant="confirm"
                  >
                   Télécharger
                  </Button>
                </div>
                
    </div>
  </div>
</div>




</div>
)}
      </div>

      
  );
}


export default UserProfil

