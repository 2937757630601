import React, { useContext } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { AuthContext } from './AuthProvider';
import Loading from './Loading';

function PrivateRoute({ children }) {
  const authContext = useContext(AuthContext);

  if (authContext.isLoading) {
    return (
      <Loading />
  );
  }

  if (!authContext.auth) {
    return <Navigate to="/login" replace />;
  }

  return children || <Outlet />;
}

export default PrivateRoute;