import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';


export const AuthContext = createContext();

const AuthProvider = ({ children }) => {
    const [auth, setAuth] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [userData, setUserData] = useState(null);


    const checkAuth = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/auth`, { withCredentials: true });
            if (response.data.isAuthenticated) {
                setAuth(true);
                setUserData(response.data.userData); // Stockez les données de l'utilisateur ici
            } else {
                setAuth(false);
            }
            setIsLoading(false);
        } catch (error) {
            console.error('Auth check failed:', error);
            setAuth(false);
            setIsLoading(false);
        }
    };


    
    useEffect(() => {
        checkAuth();
    }, []);



    return (
        <AuthContext.Provider value={{ auth, isLoading, userData }}>
            {children}
        </AuthContext.Provider>
    );
};

export default AuthProvider;