import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { usePermissions } from '../PermissionsProvider';
import { useParams } from 'react-router-dom';
import { Trash2, CircleHelp, X } from 'lucide-react';
import { toast, Zoom } from 'react-toastify';


function Ark() {

  const [ firstLaunch, setFirstLaunch ] = useState();
  const [ requestMods, setRequestMods ] = useState([]);
  const [ requestServ, setRequestServ ] = useState([]);
  const [ selectedMod, setSelectedMod ] = useState('');
  const [ cartes, setCartes ] = useState([]);
  const [ cartesJson, setCartesJson ] = useState([])
  const [ listMod, setListMod ] = useState('');
  const { hasPermission , utilisateur } = usePermissions();
  const [ modList, setModList ] = useState([]);
  const [ selectedNumber, setSelectedNumber ] = useState(4);
  const [ selectedMap, setSelectedMap ] = useState({ name: 'The Island', code: 'TheIsland_WP' });
  const [ name, setName ] = useState('');
  const [ mapName, setMapName ] = useState('');
  const [ mapCode, setMapCode ] = useState('');
  const [ isConfirmed, setIsConfirmed ] = useState(false);
  const [ message, setMessage ] = useState('');
  const [ option, setOption ]= useState('mods');
  const [ config, setConfig ] = useState({});
  const [ newParams , setNewParams ] = useState('');
  const [ newValueParams, setNewValueParams ] = useState('');  
  const [ sortedEntries, setSortedEntries ] = useState({});
  const [ defined, setDefined ] = useState('');
  const [ fireWall, setFireWall ] = useState([]);
  const [ ip, setIp ] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedLog, setSelectedLog] = useState('');
  const [logFiles, setLogFiles] = useState([]);
  const [logData, setLogData] = useState('');
  const params = useParams();

  // Filtrer les entrées en fonction du terme de recherche
  const filteredEntries = Array.isArray(sortedEntries) ? sortedEntries.filter(([key, value]) =>
    key.toLowerCase().includes(searchTerm.toLowerCase())
  ) : [];
  
  useEffect(()=>{
    
    const fetchLaunch= async() => {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/launch-ark/${params.id}`)
      setFirstLaunch(response.data.launch)
      setCartes(response.data.carte)

      // Initialiser selectedMap avec une valeur par défaut si nécessaire
      if (response.data.carte.length > 0) {
        setSelectedMap(JSON.stringify({ name: response.data.carte[0].name, code: response.data.carte[0].code }));
      } else {
        setSelectedMap(JSON.stringify({ name: 'The Island', code: 'TheIsland_WP' }));
      }
    };

    fetchLaunch();
  },[setFirstLaunch, params.id]);

  useEffect(() => {
    const fetchModsList = async () => {
      try {
        const responseMod = await axios.get(`${process.env.REACT_APP_API_URL}/mods-server/${params.id}`);
        setRequestMods(responseMod.data);
  
        const responseServ = await axios.get(`${process.env.REACT_APP_API_URL}/config-server/${params.id}`);
        const serverConfig = responseServ.data.config[0];
        setFireWall(responseServ.data.commandsFireWall);
        setIp(responseServ.data.ip);


        if (serverConfig && serverConfig.maps && serverConfig.codemap) {
          setRequestServ(serverConfig);
          setConfig(serverConfig);
          setSelectedMap(JSON.stringify({ name: serverConfig.maps, code: serverConfig.codemap }));          
        } else {
          console.error('En attente des données de configuration du serveur.');
        }
      } catch (error) {
        console.error('Erreur lors de la récupération des mods:', error);
      }
    };
  
    fetchModsList();
  }, [setRequestMods, setRequestServ, setConfig, params.id]);

  useEffect(() => {
      //classe par ordre alphabétique
    setSortedEntries(Object.entries(config).sort(([keyA], [keyB]) => keyA.localeCompare(keyB)));
  }, [config]);

  //Notification de 5 secondes sur les messages
  useEffect(() => {
    if (message) {
      const timer = setTimeout(() => {
        setMessage(null);
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [message]);

  //Première installation
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!selectedMap){

    }
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/arkserver-bat/${params.id}`, {name: name, players: selectedNumber, map: selectedMap})
    setFirstLaunch(response.data.status);
    
    const responseServ = await axios.get(`${process.env.REACT_APP_API_URL}/config-server/${params.id}`);
    setRequestServ(responseServ.data.config[0]);
    setFireWall(responseServ.data.commandsFireWall);
    window.location.reload();  
    return (firstLaunch, requestServ)   
  };

  //Configuration
    const handleSearch = async (listMod) => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/mods-list/search/${listMod}`);
          setModList(response.data.data)
      } catch (error) {
        console.error('Erreur Status');
      }
    };


  const handleModSelect = async (modId) => {
    try {
      await axios.get(`${process.env.REACT_APP_API_URL}/mods-list/${modId}`);

    } catch (error) {
      console.error('Erreur lors de la récupération des détails du mod:', error);
    }
  };

  const handleAdd = async (listMod) => {
    if(requestMods.some(installedMod => installedMod[1] === selectedMod) || selectedMod === "" ){
      return setMessage("Sélectionner correctement votre mods.");
    }
    try {      
      await axios.post(`${process.env.REACT_APP_API_URL}/mods-install/${params.id}`, { modId: selectedMod });

      const responseget = await axios.get(`${process.env.REACT_APP_API_URL}/mods-server/${params.id}`);
      setRequestMods(responseget.data);
      const responselist = await axios.get(`${process.env.REACT_APP_API_URL}/mods-list/search/${listMod}`);
      setModList(responselist.data.data);
      setMessage("Mod ajouté")

      // Historique
      axios.post(`${process.env.REACT_APP_API_URL}/info/historique`, {
        user: utilisateur,
        requestType: `${utilisateur} vient d'installer le mod ${selectedMod} sur : ${params.id}`,
        requestTime: new Date().toISOString(),
      }).catch(error => {
        console.error(`Error logging history: ${error.message}`);
      });
      setSelectedMod("")
    } catch (error) {
      console.error('Erreur lors de l\'ajout du mod:', error);
    }
  };

  const handleModDelete = async (modId) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/mods-delete/${params.id}`, { modId });
      const responseget = await axios.get(`${process.env.REACT_APP_API_URL}/mods-server/${params.id}`);
      setRequestMods(responseget.data);
      const responselist = await axios.get(`${process.env.REACT_APP_API_URL}/mods-list/search/${listMod}`);
      setModList(responselist.data.data);
      
      // Historique
      axios.post(`${process.env.REACT_APP_API_URL}/info/historique`, {
        user: utilisateur,
        requestType: `${utilisateur} vient de supprimer le mod ${modId} sur : ${params.id}`,
        requestTime: new Date().toISOString(),
      }).catch(error => {
        console.error(`Error logging history: ${error.message}`);
      });
      setMessage(response.data.message);
    } catch (error) {
      console.error('Erreur lors de la récupération des détails du mod:', error);
    }
  };

  const handleSubmitParams = async () => {
    // Check if any input field is empty
    for (const key in config) {
      if (config[key] === null || config[key] === '' ||config[key] === undefined ) {
        setMessage(`Le champ -> ${key} <- doit être rempli`);
        setDefined(key);        
        return;
      }
    }
   
    try {
      setDefined('')

      // Transformer true/false to True/False
      const transformedConfig = { ...config };
      for (const key in transformedConfig) {
        if (transformedConfig[key] === 'true') {
          transformedConfig[key] = 'True';
        } else if (transformedConfig[key] === 'false') {
          transformedConfig[key] = 'False';
        }
      }

      const response = await axios.post(`${process.env.REACT_APP_API_URL}/ark-config/${params.id}`, { config: transformedConfig });
      setMessage(response.data.message);
      
      if (response) {
        // Mettre à jour l'état config avec les données mises à jour
        setConfig(config);
        
        // Effectuer une nouvelle requête pour obtenir les données de configuration mises à jour
        const responseServ = await axios.get(`${process.env.REACT_APP_API_URL}/config-server/${params.id}`);
        setRequestServ(responseServ.data.config[0]);
        setFireWall(responseServ.data.commandsFireWall);
        setSelectedMap(JSON.stringify({ name: responseServ.data.config[0].maps, code: responseServ.data.config[0].codemap }));
        toast.success("Les paramètres ont étaient mis à jour avec succès", {
                            position: "top-center",
                            autoClose: 10000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: false,
                            progress: undefined,
                            theme: "colored",
                            transition: Zoom,
                        });
        setMessage('');                
        // Historique
        axios.post(`${process.env.REACT_APP_API_URL}/info/historique`, {
          user: utilisateur,
          requestType: `Enregistrement de nouveaux paramètres dans: ${params.id}`,
          requestTime: new Date().toISOString(),
        }).catch(error => {
          console.error(`Error logging history: ${error.message}`);
        });
      }
    } catch (error) {
      console.error('Erreur lors de la récupération des paramètres:', error);
    }
  };

  const handleMapAdd = async () => {
    if (!mapName.trim() || !mapCode.trim()) {
      setMessage('Remplir les deux champs pour la carte');
      return;
    }
    try{  
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/arkmap-add/${params.id}`, { name:mapName, code:mapCode });
    setCartesJson(response.data.maps)
    setMessage (response.data.message)
    setMapName('');
    setMapCode('');
    } catch (error) {
    console.error('Erreur lors de la récupération des paramètres:', error);
    }
  };
  
  const handleNewParamsAdd = async () => {
    if (!newParams.trim() || !newValueParams.trim()) {
      setMessage('Remplir les deux champs pour le paramètre');
      return;
    }
    try{  
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/arknewparams-add/${params.id}`, { newParams, newValueParams });
        setMessage ('Paramètre ->' + newParams + '<-' + res.data.message);

        // Effectuer une nouvelle requête pour obtenir les données de configuration mises à jour
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/config-server/${params.id}`);
        setConfig(response.data.config[0]);
        setFireWall(response.data.commandsFireWall);
        // Historique
        axios.post(`${process.env.REACT_APP_API_URL}/info/historique`, {
          user: utilisateur,
          requestType: `Un nouveau paramètre ${newParams} a été rajouté dans : ${params.id}`,
          requestTime: new Date().toISOString(),
        }).catch(error => {
          console.error(`Error logging history: ${error.message}`);
        });
        setNewParams('');
        setNewValueParams('');
    } catch (error) {
    console.error('Erreur lors de la récupération des paramètres:', error);
    }
  };


  const handleOption = async (btn) => {
    setOption(btn);
    if (btn === 'advanced') {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/ark-map/${params.id}`);
        setCartesJson(response.data);
        setSelectedMap(JSON.stringify({ name: requestServ.maps, code: requestServ.codemap }));
        setMessage('');
        const responseServ = await axios.get(`${process.env.REACT_APP_API_URL}/config-server/${params.id}`);
        const serverConfig = responseServ.data.config[0];
        setFireWall(responseServ.data.commandsFireWall);

        if (serverConfig && serverConfig.maps && serverConfig.codemap) {
          setConfig(serverConfig);
        }
      } catch (error) {
        console.error('Erreur lors de la récupération des données:', error);
      }
    } else if (btn === 'mods') {
      setMessage('');
    } else if (btn === 'logs') {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/ark-logs/${params.id}`);
        setLogFiles(response.data.logs);
      } catch (error) {
        console.error('Error fetching log files:', error);
      }
      setMessage('');                       
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'map') {
      const { name, code } = JSON.parse(value);
      setSelectedMap(value);
      
      setConfig((prevConfig) => ({
        ...prevConfig,
        maps: name,
        codemap: code,
      }));

    } else {
      setConfig((prevConfig) => ({
        ...prevConfig,
        [name]: value,
      }));
    }
  };

  const handleParamDelete = async (e) => {
    const name = e;
    axios.post(`${process.env.REACT_APP_API_URL}/arkdeleteparam/${params.id}`, { name });

    //Actualiser les paramètres
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/config-server/${params.id}`);
      const serverConfig = response.data.config[0];

      if (serverConfig && serverConfig.maps && serverConfig.codemap) {
        setConfig(serverConfig);
      } 
        
     setMessage ('Paramètre ->' + name + '<- a été supprimé');
  }

  //useEffect pour surveiller les changements dans selectedLog
  useEffect(() => {
    if (selectedLog) {
      axios.get(`${process.env.REACT_APP_API_URL}/ark-log/${params.id}`, { params: { file: selectedLog } })
        .then(response => {
          setLogData(response.data.log);
        })
        .catch(error => {
          console.error('Error fetching log data:', error);
        });
    }
  }, [selectedLog, params.id]);

  return (
    <>
    {!firstLaunch ? 

    <div className='w-full flex flex-col items-center justify-center '>
     <h2>Premier démarrage</h2>
      <form onSubmit={handleSubmit} className='w-72 flex flex-col border p-3 rounded-md gap-1 mt-2'>
        <input 
                  id="nameServer" 
                  className='p-1 bg-base-300' 
                  type="text" 
                  placeholder='Nom du serveur' 
                  value={name} 
                  onChange={(e)=>setName(e.target.value)} 
                  required 
        />
        <label> Joueurs : 
        <select
                  id="players"
                  className='ml-2 p-1 pl-3 bg-base-300 w-20'
                  value={selectedNumber}
                  onChange={(e) => {setSelectedNumber(e.target.value);}}                                                                      
        >
            <option className='text-yellow-600 text center' disabled={true}>
              --Nombre de joueurs--
            </option>
            {Array.from({ length: 67 }, (_, i) => i + 4).map((num) => (
                    <option key={num} value={num}>
                      {num}
                    </option>
            ))}
        </select> 
        </label> 
        <label className=''> Carte :         
        {cartes.length > 0 ? 
            <select
              name="mapLaunch"
              className='ml-2 p-1 bg-base-300 w-48'
              value={selectedMap}
              onChange={(e) => {setSelectedMap(e.target.value);}}
            >   
              <option className='text-yellow-600  text-center ' disabled={true}>--Cartes--</option>   
              {cartes.map((mod) => (
              <option
                key={mod.id}
                value={JSON.stringify({ name: mod.name, code: mod.code })}
              >
                  {mod.name}              
              </option>
              ))}    
            </select> 
            :
            null         
          }
        </label>
        <label>
        Confirmation : <input 
                          type="checkbox" 
                          name="myCheckbox" 
                          checked={isConfirmed} 
                          onChange={(e) => setIsConfirmed(e.target.checked)} 
                        />
        </label> 
        <div className='ml-auto'>
          <button type='submit'
              disabled={!hasPermission('accessAdmin') || !isConfirmed}
              className={
                !hasPermission('accessAdmin') || !isConfirmed
                ? 'justify-end w-56 pr-5 border rounded-ee-badge border-transparent bg-transparent  text-transparent'
                : 'justify-end w-56 pr-5 border rounded-ee-badge border-gray-400 bg-green-500 hover:bg-green-400 text-white hover:text-black'
              }
          >
              Valider et démarrer le serveur 
          </button>
        </div>         
      </form>     
      <div className='badge badge-warning badge-sm mt-3'>Les configurations avancées se font après l'initialisation </div>       
    </div> 
    
    
    :

    <div className="">
      <div className="flex flex-row gap-1 w-80">
          <button
            onClick={() => handleOption('mods')}
            className={option === 'mods' ?
              "text-xs bg-blue-800 hover:bg-blue-900 border-2 border-sky-700 text-white py-2 px-5 rounded-t-lg focus:outline-none focus:shadow-outline"
              :
              "text-xs bg-zinc-600 text-gray-400 py-2 px-2 rounded-t-lg focus:outline-none focus:shadow-outline"}
            disabled={!hasPermission('accessAdmin')}
          >
            Mods
          </button>
          <button
            onClick={() => handleOption('advanced')}
            className={option === 'advanced' ?
              "text-xs bg-blue-800 hover:bg-blue-900 border-2 border-sky-700 text-white py-2 px-5 rounded-t-lg focus:outline-none focus:shadow-outline"
              :
              "text-xs bg-zinc-600 text-gray-400 py-2 px-2 rounded-t-lg focus:outline-none focus:shadow-outline"}
            disabled={!hasPermission('accessAdmin')}
          >
            Options avancés
          </button>
          <button
            onClick={() => handleOption('logs')}
            className={option === 'logs' ?
              "text-xs bg-blue-800 hover:bg-blue-900 border-2 border-sky-700 text-white py-2 px-5 rounded-t-lg focus:outline-none focus:shadow-outline"
              :
              "text-xs bg-zinc-600 text-gray-400 py-2 px-2 rounded-t-lg focus:outline-none focus:shadow-outline"}
            disabled={!hasPermission('accessAdmin')}
          >
            Logs
          </button>
      </div>
      <div className='w-full rounded-br-xl pb-1 mb-2 bg-slate-500 items center'></div>       
      {option === 'mods' && (
      <div>
      <div className='w-full flex md:flex-row flex-col '>
        <div className='flex flex-col'>
          <div className='flex gap-x-3'>        
            
          <label className="block font-bold mb-2">Les Mods ajoutable :</label>
                  {message && <div className="text-sky-600 text-center">{message}</div>}
                </div>
          <div>
            <div className='flex text-center mt-2'>
            <input
                  type="text"
                  className="block  appearance-none  p-2 w-56 dark:bg-slate-600 bg-white border border-r-0 border-gray-400 hover:border-gray-500 px-5 rounded-ss-xl shadow leading-tight focus:outline-none focus:shadow-outline"
                  value={listMod}
                  onChange={(e) => {
                    setListMod(e.target.value);
                    handleSearch(e.target.value);
                  }}

                  placeholder= 'Recherche'
            />
          <button
            disabled={!hasPermission('accessAdmin')}
            onClick={handleAdd}
            className={!hasPermission('accessAdmin') ?
              'w-24 border border-gray-400 bg-red-500 hover:bg-red-400 text-white hover:text-black'
              :
              'w-24 border border-gray-400 bg-green-500 hover:bg-green-400 text-white hover:text-black'
            }
          >
            Ajouter
          </button>
          </div>
          {modList.length > 0 ? 
            <select
              value={selectedMod}
              onChange={(e) => {
              setSelectedMod(e.target.value);
              handleModSelect(e.target.value);
              }}
              className="block w-96 dark:bg-slate-800 bg-white border border-t-0 border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 shadow leading-tight focus:outline-none focus:shadow-outline"
            >       
              {modList.map((mod) => (
              <option
                key={mod.id}
                value={mod.id}
                className={requestMods.length > 0 && requestMods.some(installedMod => installedMod[1] === mod.id) ? 'text-lime-600': ''}
                disabled={requestMods.length > 0 && requestMods.some(installedMod => installedMod[1] === mod.id)}
              >
                  {mod.name}, id: {mod.id}                
              </option>
              ))}    
            </select> 
            :
            <div className='text-transparent px-4 py-2 pr-8'>----</div>           
          }
          <label className="block font-bold my-2">
            Mods Installés :
          </label>  
          <ul className='gap-2  text-ellipsis'>   
          {requestMods.length > 0 ?
                <>
                {requestMods.map((mod, index) => (            
                  <li 
                    key={index}
                    className='flex flex-row pl-2 py hover:bg-white dark:hover:bg-slate-500 items-center rounded-r-3xl'
                  >
                    <p 
                      className='w-28 overflow-hidden text-ellipsis whitespace-nowrap'
                    >
                      {mod[0]} , id : {mod[1]}
                    </p>
                    <button 
                      disabled={!hasPermission('accessAdmin')} 
                      className={!hasPermission('accessAdmin') ? "tooltip opacity-30 cursor-not-allowed" : "btn btn-ghost btn-xs hover:bg-transparent hover:text-red-600"}
                      data-tip={!hasPermission('accessAdmin') ? 'Non-autorisé' : ''}
                      onClick={() => handleModDelete(mod[1])}
                    >
                        <Trash2 size={16}/>
                    </button> 
                  </li>
                ))}
                </>
                : 
                null
          }  
          </ul>
        </div>
      </div>
      <div className="divider lg:divider-horizontal"></div>
      <div className='w-full h-auto max-h-80 flex flex-col text-center mx-6 my-auto rounded-3xl border-collapse border border-slate-500'>
        {requestServ ? 
            <>
              <div className='text-2xl p-1'>{requestServ.SessionName}</div>
              <p className='p-1'>Nombre de joueurs : {requestServ.WinLiveMaxPlayers}</p>
              <p className='p-1'>{requestServ.maps}</p>
              <div className='pb-1'>
                <p>Ip: {ip ? ip : null}</p>
                <p>Port serveur: {requestServ.Port}</p>
                <p>Port affichage Steam : {requestServ.QueryPort}</p>
              </div>
              
            </>
            : 
            null
        }
      </div>
         
    </div>
    {fireWall.length > 0 ? 
        <div className='mt-10 p-3 flex flex-col border rounded-2xl bg-base-content text-base-100'>
          <label className='text-red-400 dark:text-red-700 text-center text-lg'>Pour accéder au Serveur, il est nécessaire d'ouvrir les ports</label>
          <p className='text-red-400 dark:text-red-700 text-center text-xs mb-3'>(Copier les lignes ci-dessous dans le Terminal(Administrateur). Faire: Max + x)</p> 
          <p className='text-xs'>{fireWall[0]}</p>
          <p className='text-xs'>{fireWall[1]}</p>
          <p className='text-xs'>{fireWall[2]}</p>
          <p className='text-xs'>{fireWall[3]}</p>
        </div>
        :
          null
        }  
    </div>
    )}
    {option === 'advanced' && (
    <div className='w-full flex flex-col'>
      {message ?
        <p className='mt-1 mb-1 m-auto animate-bounce text-center badge badge-info h-5'>{message}</p>
        :
        <div className='mt-1 mb-1 m-auto bg-transparent h-5'></div>
      }
      <div className='w-full m-auto flex md:flex-row flex-col border-x-2 rounded-r-xl rounded-l-xl py-6'>        
        <div className='m-auto h-28 justify-center flex flex-col gap-2 px-2 border-x-2 rounded-r-xl border-l-0'> 
        <label>Carte en jeu</label>
        {cartesJson.length > 0 ? 
              <select
                name="map"
                className='ml-2 p-1 bg-base-300 w-48 cursor-pointer mb'
                value={selectedMap}
                onChange={(e) => handleChange(e)}
              >   
                <option className='text-green-400  text-center ' disabled={true}>--Cartes--</option>   
                {cartesJson.map((mod) => (
                <option
                  key={mod.code}
                  value={JSON.stringify({ name: mod.name, code: mod.code })}
                >
                    {mod.name}              
                </option>
                ))}    
              </select> 
              :
              <p>Aucune carte disponible</p>       
        } 
        </div>
        <div className='m-auto h-28 flex flex-col gap-2 px-6 border-x-2 rounded-r-xl rounded-l-xl'> 
          <label>Ajouter une carte</label>          
          <div className='flex flex-col gap-1'>
            <input
              type="text"
              placeholder="Carte"
              value={mapName}
              onChange={(e) => setMapName(e.target.value)}
            />
            <input
              type="text"
              placeholder="Nom de carte"
              value={mapCode}
              onChange={(e) => setMapCode(e.target.value)}
            />
          </div>
          <div className='flex flex-row gap-2 justify-end'>
            <button
              className="btn btn-xs btn-primary rounded-md"
              onClick={handleMapAdd}
            >
              Ajouter
            </button>
            <a 
              href='https://ark.wiki.gg/fr/wiki/R%C3%A9glage_de_serveur_d%C3%A9di%C3%A9'
              target="_blank"
              rel="noopener noreferrer"
              className="tooltip tooltip-right tooltip-warning" 
              data-tip="Cliquez pour voir le wiki"
            >
              <CircleHelp className="link link-warning"/>
            </a> 
          </div>                  
        </div>
        <div className='gap-2 m-auto flex flex-col px-2 border-x-2 rounded-l-xl border-r-0'>
          <label>Ajouter un paramètre</label>       
          <div className='flex flex-col gap-1'>
            <input
              type="text"
              placeholder="Paramètre"
              value={newParams}
              onChange={(e) => setNewParams(e.target.value)}
            />
            <input
              type="text"
              placeholder="Valeur"
              value={newValueParams}
              onChange={(e) => setNewValueParams(e.target.value)}
            />
          </div>
          <div className='flex flex-row gap-2 justify-end'>
            <button
              className="btn btn-xs btn-primary rounded-md"
              onClick={handleNewParamsAdd}
            >
              Intégrer
            </button>
          </div>
        </div>
        </div>
        <div className='flex flex-col gap-1'>
          <div className='flex'>
              <h1 className='text-xl p-2'>Paramètres</h1>
              <div className="tooltip tooltip-info" data-tip="Avant de pouvoir mettre en place les configurations, il est nécessaire d'effectuer un premier lancement du serveur">
              <CircleHelp className="link link-info h-full"/>
              </div> 
          </div>
          <div>
          <input
            type="text"
            placeholder="Rechercher..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="mb-4 p-2 border border-gray-300 rounded"
          />
          {filteredEntries.map(([key, value], index) => (
            <div key={index} className="flex items-center">
                {['maps', 'codemap', 'RCONEnabled', 'RCONPort', 'RCONServerGameLogBuffer', 'ServerAdminPassword'].includes(key) ? 
                ( 
                <button                    
                disabled     
                >
                    <X className= 'text-gray-600' />
                </button>     
                ) : (
                <button                 
                className='tooltip tooltip-error tooltip-left' 
                data-tip="Supprimer"
                onClick={() => handleParamDelete(key)}
                >
                    <X className= 'text-red-900 hover:text-red-600' />
                </button>
                )}              
              <label className='px-2 text-blue-500'>{key}:</label>
              {['true', 'false', 'True', 'False'].includes(value) ? (                  
                  <>
                  <select
                    name={key}
                    value={value.toLowerCase()}
                    onChange={handleChange}
                    className='cursor-pointer hover:bg-base-300 hover:dark:bg-slate-700 hover:rounded focus:bg-base-300 focus:dark:bg-slate-700 focus:rounded'
                  >
                    <option value="true">True</option>
                    <option value="false">False</option>
                  </select>
                  { ['RCONEnabled'].includes(key) ? (
                    <p className='ml-3 text-slate-400'>Désactiver empêche certaines options sur le site</p>     
                    ):
                    null
                  } 
                  </>       
              ) : (
                ['maps', 'codemap'].includes(key) ? (
                  <p className='ml-2 cursor-not-allowed'>{value}</p>
                ) : (
                    <input
                      placeholder={`${value}`}
                      value={value}
                      onChange={handleChange}
                      name={key}
                      className={defined === key ? 'animate-pulse border border-red-600 cursor-text' :'border-none cursor-text hover:bg-base-300 hover:dark:bg-slate-700 hover:rounded focus:bg-base-200 focus:dark:bg-slate-800 focus:rounded'}
                    />
                )
              )}
            </div>
          ))}
          </div>          
        </div>    
          <button
            className='btn btn-xs btn-primary rounded-md mt-4'
            onClick={handleSubmitParams}
          >
            Enregistrer
          </button>
          {message ?
            <p className='mt-1 m-auto text-center badge badge-warning'>{message}</p>
            :
            null
          }  
        </div>
    )}
    {option === 'logs' && (            
          <div className="p-4 bg-gray-100 dark:bg-slate-700 rounded-lg shadow-md">
          <h1 className="text-2xl font-bold mb-4">Logs Serveur</h1>
          <select
            className="select select-bordered w-full max-w-xs mb-4"
            value={selectedLog}
            onChange={(e) => setSelectedLog(e.target.value)}
          >
            <option value="">Selectionnez un fichier</option>
            {logFiles.map(file => (
              <option key={file} value={file}>{file}</option>
            ))}
          </select>
          <div className="bg-white border dark:bg-slate-500 border-gray-300 rounded-lg p-4 overflow-y-auto max-h-96">
            <pre className="whitespace-pre-wrap">{logData ? logData : null}</pre>
          </div>
        </div>   
    )} 
    </div>  
  }
  </>
  )
  
}


export default Ark