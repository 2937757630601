import React from 'react'
import axios from 'axios';
import { useState, useEffect } from 'react';
import { toast, Zoom } from 'react-toastify';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "../../ui/alert-dialog"
import { Badge } from '../../ui/badge';
import { Trash2, Check, ArrowUpFromLine, ArrowDownToLine } from 'lucide-react';
import { usePermissions } from '../Component/PermissionsProvider';

function Membres() {


  const [dataMember, setDataMember] = useState([]);
  const [dataSignup, setDataSignup] = useState([]);
  const { hasPermission, utilisateur } = usePermissions(); // Contexte des permissions
  const isButtonDisabled = !hasPermission('accessAdmin');
  const userLoged = utilisateur

  //Mis en page
  useEffect(() => {
    const fetchData = async () => {
      try {
        const responseMember = await axios.get(`${process.env.REACT_APP_API_URL}/membres`);
        setDataMember(responseMember.data);
        const responseSignup = await axios.get(`${process.env.REACT_APP_API_URL}/inscriptions`);
        setDataSignup(responseSignup.data);
      } catch (error) {
        console.error('Error fetching profiles:', error);
      }
    };
    fetchData() 
      
  }, []);

  //Pour la clarification des droit de chaque role 
  const droit= (data) => {
      if(data === 'En_attente'){
        return 'Pas d\'accès au site';
      }
      if(data === 'Membre'){
        return 'Peut démarrer, arrêter et redémarrer un serveur';
      }
      if(data === 'Admin'){
        return 'Accès à toutes les fonctionnalités, sauf la suppression des serveurs';
      }
      if(data === 'SuperAdmin'){
        return 'Toutes les fonctionnalités disponibles';
      }
      return '';
  } 

  //Passe un Membre en Admin
  const handleUp = (id, utilisateur) => {
    //action 
    axios.put(`${process.env.REACT_APP_API_URL}/grade/${id}/admin`, dataMember)
      .then((response) => {
        toast.info(utilisateur + response.data.message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
          theme: "colored",
          transition: Zoom,
        });
        // Met à jour l'état local après la mise à jour du rôle
        setDataMember(dataMember.map(member =>
          member.id === id ? { ...member, grade: 'Admin' } : member));
        //historique  
        axios.post(`${process.env.REACT_APP_API_URL}/info/historique`, {
            user: userLoged,
            requestType: utilisateur + " est passé Administrateur",
            requestTime: new Date().toISOString(),
        });
      })
      .catch((error) => {
        console.error('Error deleting signup:', error);
        toast.error('Erreur lors de la mise à jour du rôle', {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
          theme: "colored",
          transition: Zoom,
        });
      });       
  };

  //Rétrograde un Admin en Membre
  const handleDown = (id, utilisateur) => {
    //action
    axios.put(`${process.env.REACT_APP_API_URL}/grade/${id}/membre`, dataMember)
      .then((response) => {
        toast.info(utilisateur + response.data.message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
          theme: "colored",
          transition: Zoom,
        });
        // Met à jour l'état local après la mise à jour du rôle
         setDataMember(dataMember.map(member =>
          member.id === id ? { ...member, grade: 'Membre' } : member));
        //historique  
        axios.post(`${process.env.REACT_APP_API_URL}/info/historique`, {
          user: userLoged,
          requestType: utilisateur + " a était rétrogradé en Membre",
          requestTime: new Date().toISOString(),
        });
      })
      .catch((error) => {
        console.error('Error deleting signup:', error);
        toast.error('Erreur lors de la mise à jour du rôle', {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
          theme: "colored",
          transition: Zoom,
        });
      });
  };

    //Suppression definitve d'un compte utilisateur
    const handleDeleteAccount = (id, utilisateur) => {
      
      //action
      axios.post(`${process.env.REACT_APP_API_URL}/${id}/suppression`, {utilisateur} )
        .then((response) => {
          toast.info(utilisateur + response.data.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
            theme: "colored",
            transition: Zoom,
          });
          // Met à jour l'état local après la suppression
          setDataMember(dataMember.filter(member => member.id !== id));
          //historique  
          axios.post(`${process.env.REACT_APP_API_URL}/info/historique`, {
            user: userLoged,
            requestType: "Le compte de " + utilisateur + " a était supprimé definitivement",
            requestTime: new Date().toISOString(),
          });
        })
        .catch((error) => {
          console.error('Error deleting signup:', error);
          toast.error('Erreur lors de la suppression du compte', {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
            theme: "colored",
            transition: Zoom,
          });
        });
    };

  const renderTableRow = (item, index) => {
    const isButtonDisabledUp = !hasPermission('accessSuperAdmin') || item.grade === "Admin" || item.grade === "SuperAdmin";
    const isButtonDisabledDown = !hasPermission('accessSuperAdmin') || item.grade === "Membre" || item.grade === "SuperAdmin";

    return (
      <tr key={index} className="border-b hover:dark:bg-base-100 hover:bg-base-100  dark:border-stone-800 border-gray-200">
        <td>
          <div className="flex items-center gap-3">
            <div className="img">
              <div className="mask mask-squircle h-12 w-12">
                <img src={`${process.env.REACT_APP_API_URL}${item.image_path}`} alt="Avatar Tailwind CSS Component" />
              </div>
            </div>
            <div>
              <div className="font-bold">{item.utilisateur}</div>
              <div className="text-sm opacity-50">{item.email}</div>
            </div>
          </div>
        </td>
        <td>
          <Badge variant={item.grade}>{item.grade}</Badge>
          <br />
          <span className="badge badge-ghost badge-sm">{droit(item.grade)}</span>
        </td>
        <th>
          <button 
            disabled={isButtonDisabledUp} 
            className={isButtonDisabledUp ? "tooltip opacity-30 cursor-not-allowed" : " tooltip hover:bg-transparent hover:text-green-600"}
            data-tip='Passer Admin'
            onClick={() => handleUp(item.id, item.utilisateur)}
          >
            <ArrowUpFromLine />
          </button>
          <button
            onClick={() => handleDown(item.id, item.utilisateur)} 
            disabled={isButtonDisabledDown} 
            className={isButtonDisabledDown ? "tooltip opacity-30 cursor-not-allowed" : "tooltip hover:bg-transparent hover:text-red-600"}
            data-tip='Passer Membre'
          >
            <ArrowDownToLine />
          </button>
          </th>
        <th>
          <AlertDialog>
            <AlertDialogTrigger asChild>                
              <button 
                disabled= {!hasPermission('accessSuperAdmin') || item.grade === "SuperAdmin"}
                className={!hasPermission('accessSuperAdmin') || item.grade === "SuperAdmin" ? "bg-transparent text-transparent" : "btn btn-ghost hover:bg-transparent hover:text-red-600 btn-xs"}
                data-tip="Attention"
                >
                <Trash2 />
              </button> 
            </AlertDialogTrigger>
            <AlertDialogContent>
              <AlertDialogHeader>
                <AlertDialogTitle>ATTENTION</AlertDialogTitle>
                <AlertDialogDescription>
                  Vous êtes sur le point de supprimer definitivement le compte de {item.utilisateur} ?
                </AlertDialogDescription>
              </AlertDialogHeader>
              <AlertDialogFooter>
                <AlertDialogCancel>Annuler</AlertDialogCancel>
                <AlertDialogAction onClick={() => handleDeleteAccount(item.id, item.utilisateur)}>Confirmer</AlertDialogAction>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialog>    
          <AlertDialog></AlertDialog>
          <button className="btn btn-ghost btn-xs">details</button>
        </th>
      </tr>
    );
  };

  const renderTableRow2 = (item, index) => {
    return (
      <tr key={index} className="border-b hover:dark:bg-base-100 hover:bg-base-100 dark:border-stone-800 border-gray-200">
        <td>
          <div className="flex items-center gap-3">
            <div className="img">
              <div className="mask mask-squircle h-12 w-12">
                <img src={`${process.env.REACT_APP_API_URL}${item.image_path}`} alt="Avatar Tailwind CSS Component" />
              </div>
            </div>
            <div>
              <div className="font-bold">{item.utilisateur}</div>
              <div className="text-sm opacity-50">{item.email}</div>
            </div>
          </div>
        </td>
        <td className='text-xs'>
          <Badge variant={item.grade}>{item.grade}</Badge>
          <br />
          <span className="badge badge-ghost badge-sm">{droit(item.grade)}</span>
        </td>
        <th>
        <AlertDialog>
            <AlertDialogTrigger asChild>                
              <button 
                disabled={!hasPermission('accessAdmin')} 
                className={isButtonDisabled ? "tooltip opacity-30 cursor-not-allowed" : "btn btn-ghost hover:bg-transparent hover:text-red-600 btn-xs"}
                data-tip={isButtonDisabled ? 'Non-autorisé' : ''}
                >
                <Trash2 />
              </button> 
            </AlertDialogTrigger>
            <AlertDialogContent>
              <AlertDialogHeader>
                <AlertDialogTitle></AlertDialogTitle>
                <AlertDialogDescription>
                  Voulez vous vraiment supprimer {item.utilisateur} ?
                </AlertDialogDescription>
              </AlertDialogHeader>
              <AlertDialogFooter>
                <AlertDialogCancel>Annuler</AlertDialogCancel>
                <AlertDialogAction onClick={() => handleDelete(item.id, item.utilisateur)}>Confirmer</AlertDialogAction>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialog>    
          <AlertDialog>
            <AlertDialogTrigger asChild>         
              <button 
                disabled={!hasPermission('accessAdmin')} 
                className={isButtonDisabled ? "tooltip opacity-30 cursor-not-allowed" : "btn btn-ghost hover:bg-transparent hover:text-green-600 btn-xs"}
                data-tip={isButtonDisabled ? 'Non-autorisé' : ''}
                >
                <Check />
              </button>
            </AlertDialogTrigger>
            <AlertDialogContent>
              <AlertDialogHeader>
                <AlertDialogTitle></AlertDialogTitle>
                <AlertDialogDescription>
                  Voulez vous vraiment ajouter {item.utilisateur} comme membre ?
                </AlertDialogDescription>
              </AlertDialogHeader>
              <AlertDialogFooter>
                <AlertDialogCancel>Annuler</AlertDialogCancel>
                <AlertDialogAction onClick={() => handleAccept(item.id, item.utilisateur)}>Confirmer</AlertDialogAction>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialog>         
        </th>
      </tr>
    );
  };

  
//Accepter une inscription
  const handleAccept = (id, utilisateur) => {   
    //action
    axios.post(`${process.env.REACT_APP_API_URL}/inscriptions/${id}/accept`, dataSignup)
      .then((response) => {
        toast.success(response.data.message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
          theme: "colored",
          transition: Zoom,
        });
        // Mettre à jour la liste des inscriptions après acceptation
        const acceptedUser = dataSignup.find(item => item.id === id);
        setDataSignup(dataSignup.filter(item => item.id !== id));
        setDataMember([...dataMember, { ...acceptedUser, grade: 'Membre' }]);
        //historique  
        axios.post(`${process.env.REACT_APP_API_URL}/info/historique`, {
          user: userLoged,
          requestType: "L'inscription de " + utilisateur + " a était validé",
          requestTime: new Date().toISOString(),
        });
      })
      .catch((error) => {
        console.error('Error accepting signup:', error);
        toast.error('Erreur lors de l\'acceptation de l\'inscription', {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
          theme: "colored",
          transition: Zoom,
        });
      });
  };

 //Supprimer l'insciption
  const handleDelete = (id, utilisateur) => {
    //action
    axios.post(`${process.env.REACT_APP_API_URL}/inscriptions/${id}/delete`, {utilisateur})
      .then((response) => {
        toast.info(response.data.message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
          theme: "colored",
          transition: Zoom,
        });
        // Mettre à jour la liste des inscriptions après suppression
        setDataSignup(dataSignup.filter(item => item.id !== id));
        //historique  
        axios.post(`${process.env.REACT_APP_API_URL}/info/historique`, {
          user: userLoged,
          requestType: "L'inscription de " + utilisateur + " a était supprimé",
          requestTime: new Date().toISOString(),
        });
      })
      .catch((error) => {
        console.error('Error deleting signup:', error);
        toast.error('Erreur lors de la suppression de l\'inscription', {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
          theme: "colored",
          transition: Zoom,
        });
      });
  };

  return (
    <div>
    <div className="p-6 dark:bg-base-200 bg-base-200 rounded-lg shadow-md">
    <div className=" mx-auto">
      <h2 className="text-center text-2xl mb-4">Membres</h2>
      <div className="divider"></div>
        <div className="overflow-x-auto">
      <table className="table">
        <thead>
          <tr>
            <th>Utilisateur</th>
            <th>Status et droits</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {dataMember.map((item, index) => renderTableRow(item, index))}
        </tbody>
      </table>
    </div>
    </div>
  </div> 

  <div className="mt-3 p-6 dark:bg-base-200 bg-base-200 rounded-lg shadow-md">
  <div className=" mx-auto">
    <h2 className="text-center text-2xl mb-4">Inscriptions</h2>
    <div className="divider"></div>
      <div className="overflow-x-auto">
    <table className="table">
      <thead>
        <tr>
          <th>Utilisateur</th>
          <th>Status et droits</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {dataSignup.length > 0 ?
            dataSignup.map((item, index) => renderTableRow2(item, index))
            :
            <tr>
              <th className="p-10 dark:text-stone-400">Aucune inscription en attente...</th>
            </tr>
        }
      </tbody>
    </table>
  </div>
  </div>
  </div>
  </div>
  )
}

export default Membres