import React from 'react';
import { NavLink } from 'react-router-dom';

const NotFound = () => {
  return (
    <section className="page_404">
        <div className="container">
            <div className="row">	
            <div className="col-sm-12 ">
            <div className="col-sm-10 col-sm-offset-1  text-center">
            <div className="four_zero_four_bg">
                <h1 className="text-center ">404</h1>
            
            
            </div>
            
            <div className="contant_box_404">
            <h3 className="text-4xl">
            On dirait que vous êtes perdu
            </h3>
            
            <p className="text-xl">La page que vous recherchez n'est pas disponible!</p>
            
            <NavLink to="/login" className="btn btn-lg btn-info">
                    Retour
            </NavLink>
            </div>
                </div>
                </div>
                </div>
            </div>
    </section>
  );
};

export default NotFound;