import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { usePermissions } from '../Component/PermissionsProvider';

function Installation() {
  const [selectedGame, setSelectedGame] = useState('');
  const [message, setMessage] = useState('');
  const [gameList, setGameList] = useState([]);
  const [gameDetails, setGameDetails] = useState(null);
  const [dlc, setDlc] = useState([]);
  const [isInstalling, setIsInstalling] = useState(false);
  const { hasPermission, utilisateur } = usePermissions(); // Contexte des permissions
  const isButtonDisabled = !hasPermission('accessAdmin');


  useEffect(() => {
    const fetchGameList = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/game-list`);
        const sortedGameList = response.data.sort((a, b) => a.name.localeCompare(b.name));
        setGameList(sortedGameList);
      } catch (error) {
        console.error(`Error fetching game list: ${error.message}`);
      }
    };

    fetchGameList();
  }, []);

  // Check si il y a une installation en cours ou non
  useEffect(() => {
    const fetchGameStatus = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/install-status`);
        setIsInstalling(response.data.isInstalling);
      } catch (error) {
        console.error('Erreur Status');
      }
    };
    fetchGameStatus();

    const timer = setInterval(fetchGameStatus, 1000);
    return () => clearInterval(timer);
  }, []);

  const handleGameSelect = async (gameId) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/game-details/${gameId}`);
      setGameDetails(response.data);
      setDlc(response.data.dlcDetails || []);
    } catch (error) {
      console.error(`Error fetching game details: ${error.message}`);
      setMessage(`Error fetching game details: ${error.message}`);
    }
  };

  const multiDlc = (dlcs) => {
    return dlcs.map((dlc) => (
      <li key={dlc.steam_appid}>
        <div className="card bg-base-100 max-w-52 shadow-xl">
          <img src={dlc.capsule_image} alt={dlc.name} />
          <div className='card-body'>
            <h1>{dlc.name}</h1>
          </div>
        </div>
      </li>
    ));
  };

  const handleInstall = async () => {
    setMessage('');
    setIsInstalling(true);
    const name = gameDetails.name
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/install-game`, { gameId: selectedGame });
      setMessage(response.data.message);
      setIsInstalling(response.data.isInstalling);
    } catch (error) {
      if (error.response) {
        console.error(`Server error: ${error.response.data.error}`);
        setMessage(`Error: ${error.response.data.error}`);
      } else {
        console.error(`Client error: ${error.message}`);
        setMessage(`Error: ${error.message}`);
      }
    } finally {
      // Historique
      axios.post(`${process.env.REACT_APP_API_URL}/info/historique`, {
        user: utilisateur,
        requestType: `${utilisateur} vient d'installer un serveur pour : ${name}`,
        requestTime: new Date().toISOString(),
      }).catch(error => {
        console.error(`Error logging history: ${error.message}`);
      });
      setIsInstalling(false);
    }
  };


  return (
    <div className="p-6 dark:bg-base-200 bg-base-200 rounded-lg shadow-md">
        <div className="mx-auto">
          <h2 className="text-center text-2xl font-bold mb-4">Installation des jeux</h2>
          <div className="divider"></div>
          <div className='flex flex-wrap gap-4'>
            <div className="card w-[450px] bg-base-100 shadow-xl">
              <figure>
                {gameDetails ? (
                  <img src={gameDetails.header_image} alt={gameDetails.name} />
                ) : (
                  <img src="/Install.png" alt="Album" />
                )}
              </figure>
              <div className="card-body">        
                <label className="block font-bold mb-2">
                  Sélectionnez un jeu :
                </label>
                {isInstalling ? (
                      <div className="py-1 animate-pulse rounded uppercase text-center text-md bg-green-600 ...">                  
                      Un serveur est en cours d'installation...
                    </div>
                    ):(                    
                    <select
                      value={selectedGame}
                      onChange={(e) => {
                        setSelectedGame(e.target.value);
                        handleGameSelect(e.target.value);
                      }}
                      className="block  w-full  dark:bg-slate-800 bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
                    >
                      <option value="" disabled={gameDetails} className='text-gray-500'>Liste des serveurs de jeux</option>
                      {gameList.map((game) => (
                        <option key={game.id} value={game.id}>
                          {game.name}
                        </option>
                      ))}
                    </select>
                )}
                {gameDetails && (
                  <div className="mb-4">
                    <h2 className="card-title">{gameDetails.name}</h2>
                    <p>{gameDetails.short_description}</p>
                    {message && <p className="mt-4 animate-bounce text-center text-gray-50 rounded-lg ring-offset-2 ring-4 bg-blue-500 shadow-lg shadow-blue-500/50">{message}</p>}
                  </div>
                )}
                <div className="card-actions justify-end">
                  <button
                    onClick={handleInstall}
                    disabled={isButtonDisabled || isInstalling || gameDetails === null}
                    className={isButtonDisabled || isInstalling || gameDetails === null ? "tooltip cursor-not-allowed bg-red-600 hover:text-red-300 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" : "bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"}
                  >
                    Installer
                  </button>
                </div>
              </div>
            </div>
            {dlc.length > 0 && (
              <div className='max-w-[1100px]'>
                <h4 className="text-lg text-center font-bold mt-4 mb-4">DLCs disponibles :</h4>
                <ul className='flex flex-wrap justify-center gap-2'>
                  {multiDlc(dlc)}
                </ul>
              </div>
            )}  
          </div>
        </div>
    </div>
  );
}

export default Installation;
