import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { NavLink, Outlet, useNavigate, useParams } from 'react-router-dom';
import { usePermissions } from '../Component/PermissionsProvider';
import { toast, Zoom } from 'react-toastify';

function Serveur() {
  const { id } = useParams();
  const [ server, setServer ] = useState(null);
  const [ error, setError ] = useState(null);
  const [ confirmation, setConfirmation ] = useState('');
  const { hasPermission, utilisateur} = usePermissions();
  const [ pending, setPending ] = useState(false);
  const [ message, setMessage ] = useState('');
  const navigate = useNavigate();
    
  useEffect(() => {
    const fetchServer = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/servers/${id}`);
        setServer(response.data);
      } catch (error) {
        console.error(`Error fetching server: ${error.message}`);
        setError('Erreur lors de la récupération du serveur.');
      }
    };

    fetchServer();
  }, [id]);

  useEffect(() => {
    const fetchPending = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/status-server/${id}`);
        setPending(response.data.isPending); 
        setMessage(response.data.message);
      } catch (error) {
        console.error(`Error fetching server: ${error.message}`);
      }
    };

    fetchPending();
    
    const timer = setInterval(fetchPending, 1000);
    return () => clearInterval(timer);
  }, [id]);

  const handleStartServer = async () => {
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/start-server`, { serverId: id });
    } catch (error) {
      console.error(`Error starting server: ${error.message}`);
      setError('Erreur lors du démarrage du serveur.');
    }
  };

  const handleStopServer = async () => {
    try {
      axios.post(`${process.env.REACT_APP_API_URL}/stop-server`, { serverId: id });
    } catch (error) {
      console.error(`Error stopping server: ${error.message}`);
      setError('Erreur lors de l\'arrêt du serveur.');
    }
  };

  const handleRestartServer = async () => {
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/restart-server`, { serverId: id });
    } catch (error) {
      console.error(`Error restarting server: ${error.message}`);
      setError('Erreur lors du redémarrage du serveur.');
    }
  };


  const handleDeleteServer = async () => {
    if (confirmation === 'EFFACER') {
      try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/delete-server`, { serverId: id });
        toast.warn(response.data.message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
          theme: "colored",
          transition: Zoom,
        });        
        navigate('/dashboard/serveurs'); // Rediriger après suppression
        //historique  
        axios.post(`${process.env.REACT_APP_API_URL}/info/historique`, {
          user: utilisateur,
          requestType: response.data.message,
          requestTime: new Date().toISOString(),
        });
      } catch (error) {
        console.error(`Error deleting server: ${error.message}`);
        setError('Erreur lors de la suppression du serveur.');
      }
    } else {
      setError('Veuillez écrire "EFFACER" en majuscule pour confirmer la suppression.');
    }
  };



  if (!server) {
    return <div className="text-center text-lg">Chargement du serveur...</div>;
  }

  return (
    <div className='flex flex-wrap gap-6'>
      <div className="card bg-base-100 max-w-[900px] shadow-xl">
        <figure>
          <video controls loop muted autoPlay>
            <source src={server.movie} type="video/mp4"/>
            Votre navigateur ne supporte pas la vidéo.
          </video>
        </figure>
        <div className="items-center ml-1 gap-x-1 card-actions">
          <button
            className="px-5 py-2 items-center justify-center text-sm font-medium rounded-b-3xl bg-green-500 hover:bg-green-400 text-white hover:text-black"
            onClick={handleStartServer}
            disabled={pending}
          >
              {pending ? 
                <p className='loading loading-ring loading-xs mx-7'></p> 
                : 
                <>Démarrer</>
              }
          </button>
          <button
            className={!pending ?
              "px-6 py-2 items-center justify-center text-sm font-medium rounded-b-3xl bg-red-500  text-red-800 "
              :
              "px-6 py-2 items-center justify-center text-sm font-medium rounded-b-3xl bg-red-500 hover:bg-red-400 text-white hover:text-black"
            }
            onClick={handleStopServer}
            disabled={!pending}>
              Arrêter
          </button>
          <button
            className={!pending ?
              "px-6 py-2 items-center justify-center text-sm font-medium rounded-b-3xl bg-cyan-500  text-red-800 "
              :
              "px-6 py-2 items-center justify-center text-sm font-medium rounded-b-3xl bg-cyan-500 hover:bg-cyan-400 text-white hover:text-black"
            }
            onClick={handleRestartServer}
            disabled={!pending}>
              Redémarrer
          </button>
          <div className="ml-auto">
            <NavLink
              to="/dashboard/serveurs"
              className="px-6 py-2 mr-1 items-center justify-end text-sm font-medium rounded-b-3xl bg-zinc-600 hover:bg-stone-400 text-white hover:text-black"
            >
                Retour
            </NavLink>
          </div>
        </div>   
        <p className={pending ? 
          'mt-3 pl-3 btn animate-pulse bg-opacity-5 bg-green-500 rounded rounded-xs'
          :
          'mt-3 pl-3 btn bg-opacity-5 bg-red-500 rounded rounded-xs'
          }>
          {message ? 
            message : null
          }
        </p> 
        <div className="card-body">
          <h2 className="card-title">
            {server.title}
            <div className="badge badge-secondary">{server.game}</div>
          </h2>
        <div className="card bg-base-200 max-w-[800px] shadow-xl gap-2 p-2">
          <Outlet />
        </div>
          {hasPermission('accessSuperAdmin') ?         
            <div className="mt-4">
            <div className='flex gap-x-3'>
              <label className="block font-bold mb-2">Supprimer le serveur :</label>
              {error && <div className="text-red-500 text-center">{error}</div>}
            </div>  
            <div className='flex gap-2'>
              <input
              type="text"
              className="block appearance-none w-44 dark:bg-slate-600 bg-white border border-gray-400 hover:border-gray-500 px-5 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
              value={confirmation}
              onChange={(e) => setConfirmation(e.target.value)}
              placeholder="Entrez 'EFFACER'"
              />
              <button 
                className="bg-red-500 hover:bg-red-700 text-white py-2 px-4 rounded focus:outline-none focus:shadow-outline" 
                onClick={handleDeleteServer}
                disabled={!hasPermission('accessSuperAdmin')}>
                Supprimer
              </button>
            </div>       
          </div>
          :
          null
          }
        </div>   
      </div>
    </div>  
  );
}

export default Serveur;
