import React, { createContext, useContext, useEffect, useState } from 'react';
import axios from 'axios';


const PermissionsContext = createContext();

export const usePermissions = () => {
  return useContext(PermissionsContext);
};

export const PermissionsProvider = ({ children }) => {
  const [authSecur, setAuthSecur] = useState(false);
  const [gradeData, setGradeData] = useState([]);
  const [utilisateur, setUtilisateur] = useState(null);
  const [utilisateurImage, setUtilisateurImage] = useState(null);

  const roles = {
    En_attente: [],
    Membre: ['accessMember'],
    Admin: ['accessMember', 'accessAdmin'],
    SuperAdmin: ['accessMember', 'accessAdmin', 'accessSuperAdmin']
  };
  const fetchPermissions = async () => {
          try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/auth`, { withCredentials: true });
            if (response.data.isAuthenticated) {
              setAuthSecur(true);
              setGradeData(response.data.userData.grade);
              setUtilisateur(response.data.userData.utilisateur);
              setUtilisateurImage(response.data.userData.imagePath);
            } else {
              setAuthSecur(false);
            }
          } catch (error) {
            console.error('Auth check failed:', error);
            setAuthSecur(false);
          }
  };


  useEffect(() => {
    fetchPermissions();
  }, [authSecur]); // recharge authentofication à chaque nouvelle connexion

  const hasPermission = (action) => {
    if (authSecur && gradeData) {
      return roles[gradeData].includes(action);
    }
    return false;
  };

  return (
    <PermissionsContext.Provider value={{ hasPermission, setAuthSecur, fetchPermissions, utilisateur, utilisateurImage }}>
      {children}
    </PermissionsContext.Provider>
  );
};

