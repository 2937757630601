import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { toast, Zoom } from "react-toastify";
import { Button } from "../../ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../../ui/card";
import { Input } from "../../ui/input";
import { Label } from "../../ui/label";
import Themes from "../Component/Themes";

function ResetPassword() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get("token");
  const navigate = useNavigate();
  const [isDisable, setIsDisable] = useState(true);
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const passRegex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&_-])[A-Za-z\d@$!%*#?&_-]{8,}$/;

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!passRegex.test(newPassword)) {
      toast.error("Le mot de passe doit contenir au moins 8 caractères, une lettre, un chiffre et un caractère spécial.", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        theme: "colored",
        transition: Zoom,
      });
      return;
    }

    if (newPassword !== confirmPassword) {
      toast.error("Les mots de passe ne correspondent pas", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        theme: "colored",
        transition: Zoom,
      });
      return;
    }

    axios
      .post(`${process.env.REACT_APP_API_URL}/reset-password`, { token, newPassword })
      .then((response) => {
        const pass = response.data.pass;
        if (pass) {
          setIsDisable(false);
          toast.success(response.data.message, {
            position: "top-center",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
            theme: "colored",
            transition: Zoom,
          });
          setTimeout(() => {
            navigate("/login");
          }, 3000); // Ajout de 3 secondes de délai avant la redirection
        } else {
          toast.warning(response.data.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
            theme: "colored",
            transition: Zoom,
          });
        }
      })
      .catch((error) => {
        toast.error("Erreur lors de la réinitialisation du mot de passe", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
          theme: "colored",
          transition: Zoom,
        });
      });
  };

  return (
    <div className="flex items-center justify-center min-h-screen">
      <form onSubmit={handleSubmit}>
        <Card className="w-full max-w-sm">
          <CardHeader>
            <div className="flex justify-between items-center">
              <CardTitle className="text-2xl">Réinitialiser le mot de passe</CardTitle>
              <Themes />
            </div>
            <CardDescription>
              Entrer votre nouveau mot de passe
            </CardDescription>
          </CardHeader>
          <CardContent className="grid gap-4">
            <div className="grid gap-2">
              <Label htmlFor="newPassword">Nouveau mot de passe</Label>
              <Input
                id="newPassword"
                type="password"
                placeholder="Nouveau mot de passe"
                onChange={(e) => setNewPassword(e.target.value)}
                required
              />
            </div>
            <div className="grid gap-2">
              <Label htmlFor="confirmPassword">Confirmer le mot de passe</Label>
              <Input
                id="confirmPassword"
                type="password"
                placeholder="Confirmer le mot de passe"
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
              />
            </div>
          </CardContent>
          <CardFooter>
            <Button
              className="w-20 mr-5 flex items-center justify-center text-sm font-medium h-10 rounded-md px-8 border border-input bg-background hover:bg-accent hover:text-accent-foreground"
              onClick={() => navigate("/login")}
            >
              Retour
            </Button>
            <Button
              className="w-60 text-white bg-gradient-to-br from-purple-600 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm text-center"
              type="submit"
              disabled={!isDisable}
            >
              Réinitialiser
            </Button>
          </CardFooter>
        </Card>
      </form>
    </div>
  );
}

export default ResetPassword;