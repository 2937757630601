import React from "react"
import { NavLink } from "react-router-dom"
import { useState } from "react"
import axios from "axios"
import { toast, Zoom } from "react-toastify"
import { Button } from "../../ui/button"
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../../ui/card"
import { Input } from "../../ui/input"
import { Label } from "../../ui/label"
import Themes from "../Component/Themes"

function PassForgot() {

    const [email,setEmail] = useState()  
    const [isDisable, setIsDisable] = useState(true)
    const handleSubmit = (event) => {
        event.preventDefault(); // empêche le navigateur de soumettre le formulaire      
        //Check utilisateur and password true
            axios.post(`${process.env.REACT_APP_API_URL}/email`, {email})
            .then((response) => {
                    const emailPost = response.data.route 
                    setEmail("")  
                    if(emailPost){
                        setIsDisable(false)
                        toast.success(response.data.message, {
                            position: "top-center",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: false,
                            progress: undefined,
                            theme: "colored",
                            transition: Zoom,
                          });
                    }else{
                        toast.error(response.data.message, {
                            position: "top-center",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: false,
                            progress: undefined,
                            theme: "colored",
                            transition: Zoom,
                          });
                    }
                        
                        
            });
        };

    return (
    <div className="flex items-center justify-center min-h-screen">
           <video loop muted autoPlay  className="absolute top-0 left-0 w-full h-full object-cover">
            <source src="/background01.mp4" type="video/mp4"/>
          </video>
        <form className='absolute' onSubmit={handleSubmit}>
            <Card className="bg-base-100 bg-opacity-80 w-full max-w-sm">
            <CardHeader>
                <div className="flex justify-between items-center">
                    <CardTitle className="text-2xl">Mot de passe oublié</CardTitle>
                    <Themes />
                </div>
                <CardDescription>
                Entrer l'email correspondant a votre compte
                </CardDescription>
            </CardHeader>
            <CardContent className="grid gap-4">
                <div className="grid gap-2">
                <Label htmlFor="email">Email</Label>
                <Input
                        id="email" 
                        type="email" 
                        placeholder='blabla@exemple.com'
                        onChange={(e)=>setEmail(e.target.value)}  
                        required  
                />
                </div>
            </CardContent>
            <CardFooter>
                <NavLink to="/login" className="w-20 mr-5 flex items-center justify-center text-sm font-medium h-10 rounded-md px-8 border border-input bg-background hover:bg-accent hover:text-accent-foreground">
                    Retour
                </NavLink>
                
                <Button 
                    className="w-52 text-white bg-gradient-to-br from-purple-600 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm text-center "
                    disabled={!isDisable}
                    >
                    Envoyer
                </Button>               
            </CardFooter>
            </Card>
        </form>
    </div>    
    )
}
export default PassForgot


