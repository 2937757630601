import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

function Serveurs() {
  const [servers, setServers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [install, setInstall] = useState('');
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchServers = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/servers`);
        setServers(response.data);

      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchServers();
  }, []);

  useEffect(() =>{
    // verifie que le Serveur n'est pas en cour de création 
    const fetchInstall = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/servers-pending`);            
        setInstall(response.data);
        
      } catch (error) {
        setError(error.message);
      }
    }

    fetchInstall();
  },[setInstall]);

  const generateServerCard = (server) => {
    const handleNavigate = () => {
      navigate(`/dashboard/serveurs/${server.id}/${server.code}`);
    };

    return (
      <div key={server.id} className={server.id === install ?"animate-pulse card bg-red-900 bg-opacity-15 w-96 shadow-xl" : "card bg-base-100 w-96 shadow-xl"}>
        <figure>
          <img src={server.imageSrc} alt={server.game} />
        </figure>
        <div className="card-body">
          <h2 className="card-title">
            {server.title}
            <div className="badge badge-secondary">{server.game}</div>
          </h2>
          <p>{server.description}</p>
          <div className="card-actions justify-end">
            {server.id === install ? (
              <button className="badge badge-warning hover:bg-red-700" disabled>
              Installation en cours
              </button>
            ):(
              <button onClick={handleNavigate} className="badge badge-outline hover:bg-green-700">
              Options
              </button>
            )}     
          </div>
        </div>
      </div>
    );
  };
 
  if (loading) {
    return <div className="text-center text-lg">Chargement des serveurs...</div>;
  }

  if (error) {
    return <div className="text-center text-red-500">Erreur: {error}</div>;
  }

  return (
    <div className="p-6 dark:bg-base-200 bg-base-200 rounded-lg shadow-md">
      <div className="mx-auto">
        <h2 className="text-center text-2xl font-bold mb-4">Serveurs de jeux</h2>
        <div className="divider"></div>
        <div className="flex flex-wrap justify-center gap-5">
          {servers.map(generateServerCard)}
        </div>
      </div>
    </div>
  );
}

export default Serveurs;
