import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { usePermissions } from '../Component/PermissionsProvider';
import { Button } from '../../ui/button';

const Chatbox = () => {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const { utilisateur, utilisateurImage } = usePermissions();
  const chatWindowRef = useRef(null);
  const formatDate = {
    day: 'numeric',
    month: 'numeric',
  };
  const formatHeure = {
    hour: '2-digit',
    minute: '2-digit',
  }
  const date = new Date().toLocaleString([], formatDate);
  const heure = new Date().toLocaleString([], formatHeure);

  useEffect(() => {
    // Charger les messages depuis le serveur
    const fetchMessages = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/messages`);
        setMessages(response.data);
      } catch (error) {
        console.error('Failed to fetch messages:', error);
      }
    };

    fetchMessages();

    // Mettre à jour les messages toutes les 5 secondes
    const interval = setInterval(fetchMessages, 5000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    // Défiler vers le bas chaque fois que les messages sont mis à jour
    if (chatWindowRef.current) {
      chatWindowRef.current.scrollTop = chatWindowRef.current.scrollHeight;
    }
  }, [messages]);

  useEffect(() => {
    // Réinitialise le compteur de messages non lus lorsque la chatbox est ouverte
    const resetUnreadMessagesCount = async () => {
      try {
        await axios.get(`${process.env.REACT_APP_API_URL}/reset-unread-messages-count`, { withCredentials: true });
      } catch (error) {
        console.error('Failed to reset unread messages count:', error);
      }
    };
    resetUnreadMessagesCount();

    const interval = setInterval(resetUnreadMessagesCount, 5000);

    return () => clearInterval(interval);
  }, []);

  const handleSendMessage = async () => {
    if (input.trim() !== '') {
      const newMessage = { text: input, sender: 'user', utilisateur: utilisateur, imagePath: utilisateurImage, date: date, heure: heure };
      setMessages([...messages, newMessage]);
      setInput('');

      // Envoyer le message au serveur
      try {
        await axios.post(`${process.env.REACT_APP_API_URL}/messages`, newMessage);
      } catch (error) {
        console.error('Failed to send message:', error);
      }
    }
  };

  return (
    <div className="mt-20 p-6 bg-base-200 dark:bg-base-200 rounded-lg shadow-md w-full max-w-6xl mx-auto">
      <h2 className="text-center text-2xl font-bold mb-4">Chatbox</h2>
      <div className="divider"></div>

      <div ref={chatWindowRef} className="chat-window max-h-[500px] overflow-x-hidden p-2">
        {messages.map((message, index) => (
          <div
            key={index}
            className={`message p-2 rounded-lg flex items-center ${
              message.utilisateur === utilisateur ? 'justify-start' : 'justify-end'
            }`}
          >
            {message.utilisateur === utilisateur ? (
              <>
                <img src={`${process.env.REACT_APP_API_URL}${message.imagePath}`} alt="IMG" className="w-8 h-8 rounded-full mr-2" />
                {message.date === date ? (
                <div className="flex-grow p-2 mr-4">
                  <p className='text-cyan-600 text-start'><strong>{message.utilisateur}</strong></p>
                  <p className="break-words p-2 rounded-box rounded-tl-none bg-opacity-30 dark:bg-opacity-10 dark:bg-cyan-600 bg-cyan-500 inline-block max-w-full">{message.text}</p>
                </div>
                ):(
                  <div className="flex-grow p-2 mr-4">
                  <p className='text-cyan-600 text-start'><strong>{message.utilisateur}</strong></p>
                  <p className='text-start text-xs text-stone-500' >{message.date} à {message.heure}</p>
                  <p className="break-words p-2 rounded-box rounded-tl-none bg-opacity-30 dark:bg-opacity-10 dark:bg-cyan-600 bg-cyan-500 inline-block max-w-full">{message.text}</p>
                </div>
                )
                }

              </>
            ) : (
              <>
                <div className="flex-grow-0 ml-4">
                  {message.date === date ? (
                    <>                       
                      <p className='text-lime-400 text-end'><strong>{message.utilisateur}</strong></p>
                      <p className='text-end text-xs text-stone-500' >à {message.heure}</p>
                      <p className="break-words p-2 rounded-box rounded-tr-none bg-opacity-30 dark:bg-opacity-10 dark:bg-lime-600 bg-lime-500 inline-block max-w-full">{message.text}</p>
                    </>
                  ):(
                    <>
                      <p className='text-lime-400 text-end'><strong>{message.utilisateur}</strong></p>
                      <p className='text-end text-xs text-stone-500' >{message.date} à {message.heure}</p>  
                      <p className="break-words p-2 rounded-box rounded-tr-none bg-opacity-30 dark:bg-opacity-10 dark:bg-lime-600 bg-lime-500 inline-block max-w-full">{message.text}</p>
                    </>
                  )
                  }                
                </div>
                <img src={`${process.env.REACT_APP_API_URL}${message.imagePath}`} alt="IMG" className="w-8 h-8 rounded-full ml-2" />
              </>
            )}
          </div>
        ))}
      </div>
      <div className="chat-input flex mt-4">
        <input
          type="text"
          value={input}
          onChange={(e) => setInput(e.target.value)}
          onKeyUp={(e) => {
            if (e.key === 'Enter') {
              handleSendMessage();
            }
          }}
          className="input input-bordered w-full"
        />
        <Button
          onClick={handleSendMessage}
          className="btn btn-primary ml-2"
          variant="confirm"
        >
          Envoyer
        </Button>
      </div>
    </div>
  );
};

export default Chatbox;